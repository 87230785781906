define('app/parts/currentconfiguration',['exports', 'lib/shim', 'app/abstract/part'], function (exports, _shim, _part) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.CurrentConfigurationPart = undefined;

	function _classCallCheck(instance, Constructor) {
		if (!(instance instanceof Constructor)) {
			throw new TypeError("Cannot call a class as a function");
		}
	}

	function _possibleConstructorReturn(self, call) {
		if (!self) {
			throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
		}

		return call && (typeof call === "object" || typeof call === "function") ? call : self;
	}

	var _get = function get(object, property, receiver) {
		if (object === null) object = Function.prototype;
		var desc = Object.getOwnPropertyDescriptor(object, property);

		if (desc === undefined) {
			var parent = Object.getPrototypeOf(object);

			if (parent === null) {
				return undefined;
			} else {
				return get(parent, property, receiver);
			}
		} else if ("value" in desc) {
			return desc.value;
		} else {
			var getter = desc.get;

			if (getter === undefined) {
				return undefined;
			}

			return getter.call(receiver);
		}
	};

	var _createClass = function () {
		function defineProperties(target, props) {
			for (var i = 0; i < props.length; i++) {
				var descriptor = props[i];
				descriptor.enumerable = descriptor.enumerable || false;
				descriptor.configurable = true;
				if ("value" in descriptor) descriptor.writable = true;
				Object.defineProperty(target, descriptor.key, descriptor);
			}
		}

		return function (Constructor, protoProps, staticProps) {
			if (protoProps) defineProperties(Constructor.prototype, protoProps);
			if (staticProps) defineProperties(Constructor, staticProps);
			return Constructor;
		};
	}();

	function _inherits(subClass, superClass) {
		if (typeof superClass !== "function" && superClass !== null) {
			throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
		}

		subClass.prototype = Object.create(superClass && superClass.prototype, {
			constructor: {
				value: subClass,
				enumerable: false,
				writable: true,
				configurable: true
			}
		});
		if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
	}

	//###[ CONSTANTS ]######################################################################################################

	var TEMPLATE_SELECTOR = '#currentconfiguration-part-template';

	//###[ SCHEMA ]#########################################################################################################

	var SCHEMA = {
		type: 'object',
		properties: {
			'model': {
				type: 'object',
				properties: {
					// nothing yet
				}
			}
		}
	};

	//###[ CURRENTCONFIGURATIONPART ]#######################################################################################

	/**
  * Manages the display of the currently selected configuration for all app steps based on App.stepValues.*.description
  **/

	var CurrentConfigurationPart = exports.CurrentConfigurationPart = function (_Part) {
		_inherits(CurrentConfigurationPart, _Part);

		_createClass(CurrentConfigurationPart, null, [{
			key: 'register',


			//###( STATIC )###

			value: function register() {
				_part.Part.register('CurrentConfigurationPart', CurrentConfigurationPart, TEMPLATE_SELECTOR);
			}

			//###( CLASS )###

		}]);

		function CurrentConfigurationPart(params) {
			_classCallCheck(this, CurrentConfigurationPart);

			// strip possible observables
			params = ko.viewmodel.toModel(params);
			var paramsInspection = _shim.SchemaInspector.validate(SCHEMA, params);
			$.assert(paramsInspection.valid, 'CurrentConfigurationPart.constructor | params does not fit schema (' + paramsInspection.format() + ')');

			var _this = _possibleConstructorReturn(this, (CurrentConfigurationPart.__proto__ || Object.getPrototypeOf(CurrentConfigurationPart)).call(this, $.extend(true, {
				model: {
					// nothing yet
				}
			}, params)));

			_this.viewModel.stepInfo = ko.observableArray(_this.app.getStepInfo());
			// addition to get a slice of the info, acting as a display row in the template
			_this.viewModel.stepInfo.getRow = function (num) {
				var slice = ko.computed(function () {
					_this.viewModel.stepInfo();
					return _.slice(_this.viewModel.stepInfo(), 3 * (num - 1), 3 * num);
				});
				_this._addDisposable(slice);

				return slice;
			};

			_this.viewModel.isOpen = ko.observable(false);
			_this.viewModel.isOpenCompletely = ko.observable(false);
			_this.viewModel.isComplete = ko.observable(false);

			_this._addDisposable(_this.app.viewModel.stepValuesChanged.subscribe(function () {
				_this.update();
			}));

			_this._addDisposable(_this.app.viewModel.configIsComplete.subscribe(function (newValue) {
				_this.viewModel.isComplete(newValue);
			}));

			// react to global event opening the finalize step
			$(_this.app).on('openfinalize.app', function () {
				if (_this.viewModel.isOpen()) {
					_this.toggle(null, null, false);
				}
			});

			// react to global event closing the finalize step
			$(_this.app).on('closefinalize.app', function () {
				// nothing yet
			});

			_this.update();
			return _this;
		}

		_createClass(CurrentConfigurationPart, [{
			key: 'dispose',
			value: function dispose() {
				$(this.app).off('openfinalize.app closefinalize.app');

				_get(CurrentConfigurationPart.prototype.__proto__ || Object.getPrototypeOf(CurrentConfigurationPart.prototype), 'dispose', this).call(this);
			}

			//###( FUNCTIONALITY )###

		}, {
			key: 'update',
			value: function update() {
				var _this2 = this;

				var currentStepNumber = this.app.getCurrentStepNumber();

				_.each(this.viewModel.stepInfo(), function (stepInfoPart) {
					// add specific observables for configuration display to our local copy of stepInfo
					if (!$.isFunction(stepInfoPart.visible)) {
						stepInfoPart.visible = ko.observable(false);
					}

					if (!$.isFunction(stepInfoPart.selected)) {
						stepInfoPart.selected = ko.observable(false);
					}

					if (!$.isFunction(stepInfoPart.description)) {
						stepInfoPart.description = ko.observable('');
					}

					stepInfoPart.visible(stepInfoPart.number <= currentStepNumber);
					stepInfoPart.selected(stepInfoPart.number == currentStepNumber);

					// if step has already been filled, build a human-readable description, for the step's selected values
					if (stepInfoPart.visible()) {
						var stepValues = _this2.app.getStepValues(stepInfoPart.name),
						    description = '';

						_.each(stepValues, function (val) {
							description += $.strReplace(';', '; ', val.description) + '; ';
						});

						stepInfoPart.description($.strReplace('; ;', '; ', _.trim(description, ' ;')));
					} else {
						stepInfoPart.description('');
					}
				});

				// manage visibility of complete message in the config layer
				$('[data-id="' + this.viewModel.id() + '"]').closest('footer').removeClass('complete');
				if (this.viewModel.stepInfo().length > 0) {
					if (this.viewModel.isComplete()) {
						$('[data-id=' + this.viewModel.id() + ']').closest('footer').addClass('complete');
					}
				}

				// force template update
				this.viewModel.stepInfo.notifySubscribers();
			}

			/**
    * Used to open and close the footer around the config layer, normally toggles the current state,
    * but can also receive a preset, setting the state, true being open.
    *
    * Used as click handler, set $data and event to null otherwise.
   	 **/

		}, {
			key: 'toggle',
			value: function toggle($data, event, preset) {
				var _this3 = this;

				preset = $.orDefault(preset, null, 'bool');

				var $footer = $('[data-id="' + this.viewModel.id() + '"]').closest('footer');

				// reset list subscrolling on open/close
				$('[data-id="' + this.viewModel.id() + '"]').find('.config-by-steps').first().scrollTop(0);

				if (preset === true || !this.viewModel.isOpen()) {
					this.viewModel.isOpen(true);
					$footer.addClass('open');

					// dirty hack (because hard coded css-timing) to set additional class on animation finish
					// is needed, to control overflow behaviour of scaled container, removing ugly scaling scrollbar during
					// animation
					$.schedule(500, function () {
						_this3.viewModel.isOpenCompletely(true);
						$footer.addClass('open-finished');
					});
				} else if (preset === false || this.viewModel.isOpen()) {
					this.viewModel.isOpen(false);
					this.viewModel.isOpenCompletely(false);
					$footer.removeClass('open open-finished');
				}
			}

			/**
    * Opens the finalize step from the config layer, is normally click-triggered by the button in the complete
    * message beneath the config.
    **/

		}, {
			key: 'openFinalizeConfig',
			value: function openFinalizeConfig() {
				this.app.openFinalizeConfig();
			}
		}]);

		return CurrentConfigurationPart;
	}(_part.Part);
});
