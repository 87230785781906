define('app/subcomponents/selectelement',['exports', 'lib/shim', 'util/tracking', 'app/abstract/subcomponent'], function (exports, _shim, _tracking, _subcomponent) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.SelectElementSubComponent = undefined;

	function _classCallCheck(instance, Constructor) {
		if (!(instance instanceof Constructor)) {
			throw new TypeError("Cannot call a class as a function");
		}
	}

	function _possibleConstructorReturn(self, call) {
		if (!self) {
			throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
		}

		return call && (typeof call === "object" || typeof call === "function") ? call : self;
	}

	var _get = function get(object, property, receiver) {
		if (object === null) object = Function.prototype;
		var desc = Object.getOwnPropertyDescriptor(object, property);

		if (desc === undefined) {
			var parent = Object.getPrototypeOf(object);

			if (parent === null) {
				return undefined;
			} else {
				return get(parent, property, receiver);
			}
		} else if ("value" in desc) {
			return desc.value;
		} else {
			var getter = desc.get;

			if (getter === undefined) {
				return undefined;
			}

			return getter.call(receiver);
		}
	};

	var _createClass = function () {
		function defineProperties(target, props) {
			for (var i = 0; i < props.length; i++) {
				var descriptor = props[i];
				descriptor.enumerable = descriptor.enumerable || false;
				descriptor.configurable = true;
				if ("value" in descriptor) descriptor.writable = true;
				Object.defineProperty(target, descriptor.key, descriptor);
			}
		}

		return function (Constructor, protoProps, staticProps) {
			if (protoProps) defineProperties(Constructor.prototype, protoProps);
			if (staticProps) defineProperties(Constructor, staticProps);
			return Constructor;
		};
	}();

	function _inherits(subClass, superClass) {
		if (typeof superClass !== "function" && superClass !== null) {
			throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
		}

		subClass.prototype = Object.create(superClass && superClass.prototype, {
			constructor: {
				value: subClass,
				enumerable: false,
				writable: true,
				configurable: true
			}
		});
		if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
	}

	//###[ CONSTANTS ]######################################################################################################

	var TEMPLATE_SELECTOR = '#selectelement-subcomponent-template';

	//###[ SCHEMA ]#########################################################################################################

	var LIST_SCHEMA = {
		optional: true,
		type: 'object',
		properties: {
			'type': {
				type: 'string',
				pattern: /(pros|cons|result)/
			},

			'items': {
				type: 'array',
				items: {
					type: 'string'
				}
			}
		}
	};

	var SCHEMA = {
		type: 'object',
		model: {
			type: 'object',
			properties: {
				'title': {
					type: 'string',
					minLength: 1
				},

				'text': {
					optional: true,
					type: 'string'
				},

				'image': {
					// is validated by widget/image
					type: 'object'
				},

				'list': LIST_SCHEMA
			}
		}
	};

	//###[ SELECTELEMENTSUBCOMPONENT ]######################################################################################

	/**
  * A subcomponent of ListSelectComponent
  **/

	var SelectElementSubComponent = exports.SelectElementSubComponent = function (_SubComponent) {
		_inherits(SelectElementSubComponent, _SubComponent);

		_createClass(SelectElementSubComponent, null, [{
			key: 'register',


			//###( STATIC )###

			value: function register() {
				_subcomponent.SubComponent.register('SelectElementSubComponent', SelectElementSubComponent, TEMPLATE_SELECTOR);
			}

			//###( CLASS )###

		}]);

		function SelectElementSubComponent(params) {
			_classCallCheck(this, SelectElementSubComponent);

			// strip possible observables
			params = ko.viewmodel.toModel(params);
			var paramsInspection = _shim.SchemaInspector.validate(SCHEMA, params);
			$.assert(paramsInspection.valid, 'SelectElementSubComponent.constructor | params does not fit schema (' + paramsInspection.format() + ')');

			// determine if the element is currently selected or not
			var _this = _possibleConstructorReturn(this, (SelectElementSubComponent.__proto__ || Object.getPrototypeOf(SelectElementSubComponent)).call(this, $.extend(true, {
				model: {
					// the element's title (verbose name)
					title: '',

					// the element's explanation text, short version to display directly in the element
					text: '',

					// the element's image
					image: undefined,

					// additional list beneath the explanation text
					list: undefined
				}
			}, params)));

			_this.viewModel.isSelected = ko.computed(function () {
				_this.component.viewModel.selected();
				if (_this.component.allowMultiple) {
					var selectedSet = new Set(_this.component.viewModel.selected().split(';'));
					return selectedSet.has(_this.viewModel.value());
				} else {
					return _this.component.viewModel.selected() === _this.viewModel.value();
				}
			});
			_this._addDisposable(_this.viewModel.isSelected);

			_this.viewModel.hasText = ko.computed(function () {
				return _this.viewModel.text() !== '';
			});
			_this._addDisposable(_this.viewModel.hasText);

			//^^^
			_this.viewModel.description = ko.computed(function () {
				return _this.viewModel.title();
			});
			return _this;
		}

		_createClass(SelectElementSubComponent, [{
			key: 'dispose',
			value: function dispose() {
				_get(SelectElementSubComponent.prototype.__proto__ || Object.getPrototypeOf(SelectElementSubComponent.prototype), 'dispose', this).call(this);
			}

			//###( FUNCTIONALITY )###

			/**
    * Track selection generically, so that all inheriting classes can also track without code repetition
    **/

		}, {
			key: 'trackSelection',
			value: function trackSelection() {
				// has to be _before_ select below, because rerendering causes id to change
				var $element = $('[data-id="' + this.viewModel.id() + '"]').first();
				if ($.exists($element)) {
					_tracking.GoogleAnalytics.DataLayer.trackElementSelection($element, 'optionswahl', this.component.step.viewModel.number(), this.viewModel.title());
				}
			}

			/**
    * Set element selected, further logic is handled by parent
    **/

		}, {
			key: 'select',
			value: function select() {
				this.trackSelection();

				if (this.component.allowMultiple) {
					if (this.viewModel.isSelected()) {
						this.component.deselect(this.viewModel.value());
					} else {
						this.component.select(this.viewModel.value(), this.viewModel.description());
					}
				} else {
					this.component.select(this.viewModel.value(), this.viewModel.description());
				}
			}
		}]);

		return SelectElementSubComponent;
	}(_subcomponent.SubComponent);
});
