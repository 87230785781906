define('util/tracking',['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	/**
  * Helpers for tracking solutions like GoogleAnalytics
  **/

	var GoogleAnalytics = exports.GoogleAnalytics = {
		// DataLayer implementation is injected via TagManager on stage/live
		// see: https://developers.google.com/tag-manager/devguide
		// debugging on staging after deploy via: https://www.googletagmanager.com/set_cookie?uiv2&id=GTM-TCVJCD&gtm_auth=n-3cRvly5fvbqg0JYCTvaw&gtm_preview=env-6&gtm_debug=x&url=https://dachplaner-staging.dolle-kunststoff.de
		DataLayer: {
			/**
    * Track selection of an element bearing a value contributing to the global config, such as a selectelement
    **/
			trackElementSelection: function trackElementSelection($element, eventName, stepNumber, value) {
				$.log('', 'TrackElementSelection:', $element, eventName, stepNumber, value, '');
				// this was explicitly required by dolle in https://jira.21torr.com/browse/DOLLESUP-71
				// the subpar naming is obligatory and may not be changed!
				if ($element.length > 0 && $.exists('dataLayer') && $.isFunction(dataLayer.push)) {
					dataLayer.push({
						'event': '' + eventName,
						'currentTarget': $element.oo(),
						'innerH3': '' + value,
						'stepNumber': parseInt(stepNumber, 10)
					});
				}
			}
		}
	};
});
