define('app/abstract/component',['exports', 'lib/shim', 'app/abstract/viewmodel'], function (exports, _shim, _viewmodel) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.Component = undefined;

	function _classCallCheck(instance, Constructor) {
		if (!(instance instanceof Constructor)) {
			throw new TypeError("Cannot call a class as a function");
		}
	}

	function _possibleConstructorReturn(self, call) {
		if (!self) {
			throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
		}

		return call && (typeof call === "object" || typeof call === "function") ? call : self;
	}

	var _get = function get(object, property, receiver) {
		if (object === null) object = Function.prototype;
		var desc = Object.getOwnPropertyDescriptor(object, property);

		if (desc === undefined) {
			var parent = Object.getPrototypeOf(object);

			if (parent === null) {
				return undefined;
			} else {
				return get(parent, property, receiver);
			}
		} else if ("value" in desc) {
			return desc.value;
		} else {
			var getter = desc.get;

			if (getter === undefined) {
				return undefined;
			}

			return getter.call(receiver);
		}
	};

	var _createClass = function () {
		function defineProperties(target, props) {
			for (var i = 0; i < props.length; i++) {
				var descriptor = props[i];
				descriptor.enumerable = descriptor.enumerable || false;
				descriptor.configurable = true;
				if ("value" in descriptor) descriptor.writable = true;
				Object.defineProperty(target, descriptor.key, descriptor);
			}
		}

		return function (Constructor, protoProps, staticProps) {
			if (protoProps) defineProperties(Constructor.prototype, protoProps);
			if (staticProps) defineProperties(Constructor, staticProps);
			return Constructor;
		};
	}();

	function _inherits(subClass, superClass) {
		if (typeof superClass !== "function" && superClass !== null) {
			throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
		}

		subClass.prototype = Object.create(superClass && superClass.prototype, {
			constructor: {
				value: subClass,
				enumerable: false,
				writable: true,
				configurable: true
			}
		});
		if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
	}

	//###[ SCHEMA ]#########################################################################################################

	var SCHEMA = {
		type: 'object',
		properties: {
			'model': {
				type: 'object',
				properties: {
					'name': {
						type: 'string',
						pattern: 'alphaDash'
					},

					'neededTags': {
						optional: true,
						type: 'array',
						items: {
							type: 'string',
							pattern: 'alphaDash'
						}
					},

					'cssClasses': {
						optional: true,
						type: 'string'
					},

					'info': {
						// is validated by widget/info
						optional: true,
						type: 'object'
					},

					'headline': {
						optional: true,
						type: 'string'
					},

					'subHeadline': {
						optional: true,
						type: 'string'
					},

					'leadingCopytext': {
						optional: true,
						type: 'string'
					},

					'trailingCopytext': {
						optional: true,
						type: 'string'
					}
				}
			}
		}
	};

	//###[ COMPONENT ]######################################################################################################

	/**
  * Abstract class for step components. A component should result in a specific value to register in the global value
  * dict. A certain value of a component may result in the adding/removal of associated tags, which in case manage
  * visibility of other steps and components.
  **/

	var Component = exports.Component = function (_ViewModel) {
		_inherits(Component, _ViewModel);

		_createClass(Component, null, [{
			key: 'register',


			//###( STATIC )###

			value: function register(type, ComponentClass, templateSelector) {
				ko.components.register('' + type, {
					viewModel: {
						createViewModel: function createViewModel(params) {
							$.assert($.isSet(params.app), 'Component.register | app has not been set');
							$.assert($.isSet(params.step), 'Component.register | step has not been set');

							var component = new ComponentClass(params),
							    app = params.app,
							    step = params.step;

							// has to be defined here to get an up to date reference to internalValue
							// after possible redefinition of internal Value in constructor, otherwise
							// this computed would point to the wrong internal value!
							component.viewModel.fluxValue = ko.forcibleComputed(function () {
								return component.viewModel.internalValue();
							}).extend({ notify: 'always' });
							component._addDisposable(component.viewModel.fluxValue);

							// init value
							component.viewModel.value(component.viewModel.fluxValue());

							// init tags
							component.buildActiveTags();
							app.removeTags(component.viewModel.allTags());
							app.addTags(component.viewModel.activeTags());

							// initialize the components initial value in its step
							step.setValue(component.viewModel.name(), {
								value: component.viewModel.value(),
								description: component.viewModel.description()
							});

							// make sure that changed are user approved if they might change anything already edited
							component._handleChange();

							// define update behaviour in case component value really changes
							component._addDisposable(component.viewModel.value.subscribe(function () {
								app.removeTags(component.viewModel.allTags());
								app.addTags(component.viewModel.activeTags());
								step.updateValue(component.viewModel.name(), {
									value: component.viewModel.value(),
									description: component.viewModel.description()
								});
							}));

							return component;
						}
					},
					template: $('' + templateSelector).first().html()
				});
			}

			//###( CLASS )###

		}]);

		function Component(params) {
			_classCallCheck(this, Component);

			// strip possible observables
			params = ko.viewmodel.toModel(params);
			var paramsInspection = _shim.SchemaInspector.validate(SCHEMA, params);
			$.assert(paramsInspection.valid, 'Component.constructor | params does not fit schema (' + paramsInspection.format() + ')');

			var _this = _possibleConstructorReturn(this, (Component.__proto__ || Object.getPrototypeOf(Component)).call(this));

			_this.app = $.orDefault(params.app, null);
			$.assert($.exists('app.viewModel', _this), 'Component.constructor | app has not been set');

			_this.step = $.orDefault(params.step, null);
			$.assert($.exists('step.viewModel', _this), 'Component.constructor | step has not been set');

			_this.viewModel = ko.viewmodel.fromModel($.extend({
				// internal name under which the component's value is held in value dicts
				name: '',

				// list of tags, which must be present for this component to be displayed
				neededTags: [],

				//css classes to add to the component
				cssClasses: '',

				// additional information
				info: undefined,

				// headline to display over component
				headline: '',

				// subheadline to display under headline
				subHeadline: '',

				// leading copytext over component
				leadingCopytext: '',

				// trailing copytext under component
				trailingCopytext: ''
			}, params.model));

			// id (for id attr), enables us to select the dom element dynamically
			_this.viewModel.id = ko.observable(_.uniqueId('component_'));

			// collection of all possible tags of this component
			_this.viewModel.allTags = ko.observableArray([]);

			// currently active tags, based on current value(s)
			_this.viewModel.activeTags = ko.observableArray([]);

			//>>> overwrite this with something meaningful in extending classes, should be human-readable description of current value
			_this.viewModel.description = ko.observable('');

			//>>> overwrite this with something meaningful in extending classes, changes on this bubble up to the app
			_this.viewModel.internalValue = ko.observable('');

			// generic value holder for all components, don't set this, this is for external subscriptions, only overwrite and
			// use internalValue or included values to set new component values
			_this.viewModel.value = ko.observable('');

			_this.initAllTags();

			_this._resetNextValueUpdate();
			return _this;
		}

		_createClass(Component, [{
			key: 'dispose',
			value: function dispose() {
				this.app.removeTags(this.viewModel.allTags());
				this.step.removeValue(this.viewModel.name());

				_get(Component.prototype.__proto__ || Object.getPrototypeOf(Component.prototype), 'dispose', this).call(this);
			}

			/**
    * Remove all traces of a subcomponent from the value and description of this component.
    * Is used to remove selected subcomponents on visibility changes for example.
    **/

		}, {
			key: 'clearSub',
			value: function clearSub(subValue, description) {
				subValue = _.trim('' + subValue);
				description = $.orDefault(_.trim(description), '', 'string');

				var values = this.viewModel.internalValue().split(';'),
				    subValueIndex = _.indexOf(values, subValue);

				if (subValueIndex >= 0) {
					if (description !== '') {
						var descriptions = this.viewModel.description().split(';');
						_.remove(descriptions, function (d) {
							return d === description;
						});
						this.viewModel.description(descriptions.join(';'));
					}

					_.remove(values, function (v) {
						return v === subValue;
					});
					this.nextValueUpdate = {
						blocked: true,
						approved: true,
						changed: false
					};
					this.viewModel.internalValue(values.join(';'));
				}
			}

			//###( FUNCTIONALITY )###

			/**
    * Compile all possible tags of the components.
    * >>> overwrite this method in extending classes, this does nothing!
    **/

		}, {
			key: 'initAllTags',
			value: function initAllTags() {
				this.viewModel.allTags = ko.observableArray([]);
			}

			/**
    * Compile the currently active tags of the component, most likely after a value change, based on the current value.
    * >>> overwrite this method in extending classes, this does nothing!
    **/

		}, {
			key: 'buildActiveTags',
			value: function buildActiveTags(value) {
				this.viewModel.activeTags = ko.observableArray([]);
			}

			//###( CALLBACKS )###

			/**
    * Called after render to apply additional classes from viewModel.cssClasses
    **/

		}, {
			key: 'addCssClasses',
			value: function addCssClasses(elements, $data) {
				$('[data-id="' + $data.viewModel.id() + '"]').addClass($data.viewModel.cssClasses());
			}

			//###( PROTECTED )###

			/**
    * nextValueUpdate manages the state of an impending update, this method resets it to neutral state
    **/

		}, {
			key: '_resetNextValueUpdate',
			value: function _resetNextValueUpdate() {
				this.nextValueUpdate = {
					blocked: false,
					approved: false,
					changed: false
				};
			}

			/**
    * This method magically manages an impending value update, asking for confirmation before a destructive
    * update and maybe resetting the value of the user decides to discard the change again
    * this is achieved via the fluxValue, which mirrors the internalValue and acts as a gate keeper for changes
    * to the actual value, which then, in turn, would actually notify any external subscribers.
   	 **/

		}, {
			key: '_handleChange',
			value: function _handleChange() {
				var _this2 = this;

				this._addDisposable(this.viewModel.fluxValue.subscribe(function (oldValue) {
					var component = _this2;

					if (!component.nextValueUpdate.blocked) {
						_.each(_this2.viewModel.activeTags(), function (tag) {
							if (_this2.app.getAllNeededTags().has(tag)) {
								_this2.nextValueUpdate.blocked = true;
							}
						});

						if (_this2.nextValueUpdate.blocked) {
							_shim.bootstrapDialog.show({
								title: _this2.app.getText('changeConfirmDialogue.title'),
								message: _this2.app.getText('changeConfirmDialogue.message'),
								closable: false,
								buttons: [{
									label: _this2.app.getText('changeConfirmDialogue.yes'),
									cssClass: 'arrow',
									action: function action(modal) {
										component.nextValueUpdate.approved = true;
										component.viewModel.fluxValue.forceNotifySubscribers();
										modal.close();
									}
								}, {
									label: _this2.app.getText('changeConfirmDialogue.no'),
									cssClass: 'secondary',
									action: function action(modal) {
										component.nextValueUpdate.approved = false;
										component.nextValueUpdate.changed = true;
										component.viewModel.internalValue(oldValue);
										modal.close();
									}
								}]
							});
						}
					}
				}, null, 'beforeChange'));

				this._addDisposable(this.viewModel.fluxValue.subscribe(function (newValue) {
					if ((!_this2.nextValueUpdate.blocked || _this2.nextValueUpdate.blocked && _this2.nextValueUpdate.approved) && !_this2.nextValueUpdate.changed) {
						_this2.buildActiveTags(newValue);

						_this2._resetNextValueUpdate();

						_this2.viewModel.value(_this2.viewModel.fluxValue());
						_this2.viewModel.value.notifySubscribers();

						_this2.scrollToNextSibling();
					} else if (_this2.nextValueUpdate.changed) {
						_this2._resetNextValueUpdate();
					}
				}));
			}
		}, {
			key: 'scrollToNextSibling',
			value: function scrollToNextSibling() {
				var _this3 = this;

				$.schedule(100, function () {
					var $element = $('[data-id="' + _this3.viewModel.id() + '"]'),
					    $next = $element.nextAll('.component').first();

					if ($element.length > 0 && $next.length > 0) {
						var innerHeight = window.innerHeight || $(window).height();
						$next.scrollTo($.noop, 500, Math.round(innerHeight / 3), true);
					}
				});
			}
		}]);

		return Component;
	}(_viewmodel.ViewModel);
});
