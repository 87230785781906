define('app/parts/retailers',['exports', 'lib/shim', 'app/abstract/part'], function (exports, _shim, _part) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.RetailersPart = undefined;

	function _classCallCheck(instance, Constructor) {
		if (!(instance instanceof Constructor)) {
			throw new TypeError("Cannot call a class as a function");
		}
	}

	function _possibleConstructorReturn(self, call) {
		if (!self) {
			throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
		}

		return call && (typeof call === "object" || typeof call === "function") ? call : self;
	}

	var _get = function get(object, property, receiver) {
		if (object === null) object = Function.prototype;
		var desc = Object.getOwnPropertyDescriptor(object, property);

		if (desc === undefined) {
			var parent = Object.getPrototypeOf(object);

			if (parent === null) {
				return undefined;
			} else {
				return get(parent, property, receiver);
			}
		} else if ("value" in desc) {
			return desc.value;
		} else {
			var getter = desc.get;

			if (getter === undefined) {
				return undefined;
			}

			return getter.call(receiver);
		}
	};

	var _createClass = function () {
		function defineProperties(target, props) {
			for (var i = 0; i < props.length; i++) {
				var descriptor = props[i];
				descriptor.enumerable = descriptor.enumerable || false;
				descriptor.configurable = true;
				if ("value" in descriptor) descriptor.writable = true;
				Object.defineProperty(target, descriptor.key, descriptor);
			}
		}

		return function (Constructor, protoProps, staticProps) {
			if (protoProps) defineProperties(Constructor.prototype, protoProps);
			if (staticProps) defineProperties(Constructor, staticProps);
			return Constructor;
		};
	}();

	function _inherits(subClass, superClass) {
		if (typeof superClass !== "function" && superClass !== null) {
			throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
		}

		subClass.prototype = Object.create(superClass && superClass.prototype, {
			constructor: {
				value: subClass,
				enumerable: false,
				writable: true,
				configurable: true
			}
		});
		if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
	}

	//###[ CONSTANTS ]######################################################################################################

	var TEMPLATE_SELECTOR = '#retailers-part-template';

	var SEARCH_BASE_URL = '/api/retailers';
	var MOCK_SEARCH_BASE_URL = '/mockapi/retailers';
	var SEARCH_URL = SEARCH_BASE_URL + '/{lat}/{lng}/{limit}';
	var MOCK_SEARCH_URL = MOCK_SEARCH_BASE_URL + '/{lat}/{lng}/{limit}';
	var MOCK_API = false;
	var SEARCH_COUNT = 5;

	//###[ SCHEMA ]#########################################################################################################

	var SCHEMA = {
		type: 'object',
		properties: {
			'model': {
				type: 'object',
				properties: {
					// nothing yet
				}
			}
		}
	};

	//###[ RETAILERSPART ]##################################################################################################

	/**
  * Manages the display and functionality for the retailer search in the app's finalize step.
  **/

	var RetailersPart = exports.RetailersPart = function (_Part) {
		_inherits(RetailersPart, _Part);

		_createClass(RetailersPart, null, [{
			key: 'register',


			//###( STATIC )###

			value: function register() {
				_part.Part.register('RetailersPart', RetailersPart, TEMPLATE_SELECTOR);
			}

			//###( CLASS )###

		}]);

		function RetailersPart(params) {
			_classCallCheck(this, RetailersPart);

			// strip possible observables
			params = ko.viewmodel.toModel(params);
			var paramsInspection = _shim.SchemaInspector.validate(SCHEMA, params);
			$.assert(paramsInspection.valid, 'RetailersPart.constructor | params does not fit schema (' + paramsInspection.format() + ')');

			var _this = _possibleConstructorReturn(this, (RetailersPart.__proto__ || Object.getPrototypeOf(RetailersPart)).call(this, $.extend(true, {
				model: {
					// nothing yet
				}
			}, params)));

			_this.viewModel.retailers = ko.observableArray([]);
			_this.viewModel.searchActive = ko.observable(false);
			_this.viewModel.lastResultWasEmpty = ko.observable(false);

			_this.geoCoder = new _shim.GoogleMapsApi.Geocoder();
			_this.listDataRequest = null;
			_this.$element = $('not initialized yet, wait for prepareDom');
			return _this;
		}

		_createClass(RetailersPart, [{
			key: 'dispose',
			value: function dispose() {
				_get(RetailersPart.prototype.__proto__ || Object.getPrototypeOf(RetailersPart.prototype), 'dispose', this).call(this);
			}
		}, {
			key: 'prepareDom',
			value: function prepareDom(elements, $data) {
				_get(RetailersPart.prototype.__proto__ || Object.getPrototypeOf(RetailersPart.prototype), 'addCssClasses', this).call(this, elements, $data);

				$data.$element = $('[data-id="' + $data.viewModel.id() + '"]');

				$data.$element.find('.locator [name=location]').on('keydown.retailers', function (e) {
					// block enter/submit
					if (e.keyCode == 13) {
						e.preventDefault();
						e.stopPropagation();

						$data.search($(this).val());
					}
				}).end();

				$data.initAutoComplete();
			}
		}, {
			key: 'prepareRetailerListDom',
			value: function prepareRetailerListDom(elements, $data) {
				_.each(elements, function (element) {
					// skip text nodes
					if (element.nodeType === 1) {
						var $element = $(element).closest('.retailer-listitem');

						if (!$element.hasClass('domprepared')) {
							$element.find(':radio').makeStylable('radiolabel');
							$element.addClass('domprepared');
						}
					}
				});
			}

			//###( FUNCTIONALITY )###

		}, {
			key: 'initAutoComplete',
			value: function initAutoComplete() {
				var countryCode = $('html').dataDuo('country'),
				    autoCompleteOptions = {
					types: ['geocode']
				};

				if (!$.isEmpty(countryCode)) {
					autoCompleteOptions['componentRestrictions'] = { country: countryCode };
				}

				this.autoComplete = new _shim.GoogleMapsApi.places.Autocomplete(this.$element.find('.locator [name=location]')[0], autoCompleteOptions);

				this.autoComplete.addListener('place_changed', $.proxy(this.searchLocationsNear, this));
			}
		}, {
			key: 'search',
			value: function search(searchVal, event) {
				var _this2 = this;

				// from button click
				if ($.isSet(event)) {
					searchVal = this.$element.find('.locator [name=location]').val();
				}

				this.geoCoder.geocode({ address: searchVal }, function (results, status) {
					if (status === _shim.GoogleMapsApi.GeocoderStatus.OK) {
						var resultLocation = results[0].geometry.location;
						_this2.searchLocationsNear(new _shim.GoogleMapsApi.LatLng(resultLocation.lat(), resultLocation.lng()));
					} else {
						_this2.clearLocations();
					}
				});
			}
		}, {
			key: 'clearLocations',
			value: function clearLocations() {
				this.viewModel.retailers.removeAll();
			}
		}, {
			key: 'requestLocations',
			value: function requestLocations(url, callback) {
				var _this3 = this;

				// block parallel requests
				if (this.listDataRequest !== null && this.listDataRequest.readyState != 4) {
					this.listDataRequest.abort();
				}

				this.listDataRequest = window.ActiveXObject ? new ActiveXObject('Microsoft.XMLHTTP') : new XMLHttpRequest();

				this.listDataRequest.onreadystatechange = function () {
					// 4 = request finished and response is ready
					if (_this3.listDataRequest.readyState == 4 && _this3.listDataRequest.status == 200) {
						_this3.listDataRequest.onreadystatechange = $.noop;
						callback(_this3.listDataRequest.responseText, _this3.listDataRequest.status);
					}
				};

				this.listDataRequest.open('GET', url, true);
				this.listDataRequest.send(null);
			}
		}, {
			key: 'parseXml',
			value: function parseXml(str) {
				if (window.ActiveXObject) {
					var doc = new ActiveXObject('Microsoft.XMLDOM');
					doc.loadXML(str);

					return doc;
				} else if (window.DOMParser) {
					return new DOMParser().parseFromString(str, 'text/xml');
				}
			}
		}, {
			key: 'searchLocationsNear',
			value: function searchLocationsNear(center) {
				var _this4 = this;

				if (!center) {
					var autoCompletePlace = this.autoComplete.getPlace();

					if (autoCompletePlace && autoCompletePlace.geometry) {
						center = autoCompletePlace.geometry.location;
					}
				}

				if (center) {
					this.clearLocations();
					this.viewModel.searchActive(true);
					this.viewModel.lastResultWasEmpty(false);

					var searchUrl = !MOCK_API ? $.strFormat(SEARCH_URL, { lat: center.lat(), lng: center.lng(), limit: SEARCH_COUNT }) : $.strFormat(MOCK_SEARCH_URL, { lat: center.lat(), lng: center.lng(), limit: SEARCH_COUNT });

					this.requestLocations(searchUrl, function (data) {
						var xml = _this4.parseXml(data),
						    markerNodes = xml.documentElement.getElementsByTagName('marker'),
						    marker = null,
						    retailerUrl = null,
						    markerData = null,
						    i = 0;

						if (markerNodes.length === 0) {
							_this4.viewModel.lastResultWasEmpty(true);
						}

						_this4.viewModel.searchActive(false);

						for (i = 0; i < markerNodes.length; i++) {
							marker = markerNodes[i];

							retailerUrl = marker.getAttribute('web');
							if ($.trim(retailerUrl) !== '') {
								if (!_.startsWith(retailerUrl, 'http://') && !_.startsWith(retailerUrl, 'https://')) {
									retailerUrl = 'http://' + retailerUrl;
								}
							} else {
								retailerUrl = '';
							}

							markerData = {
								id: 'retailer_' + (i + 1),
								name: marker.getAttribute('name'),
								address: marker.getAttribute('address'),
								phone: marker.getAttribute('phone'),
								cleanphone: $.strReplace(['-', '/', '(', ')', ' '], '', marker.getAttribute('phone')),
								fax: marker.getAttribute('fax'),
								email: marker.getAttribute('email'),
								web: marker.getAttribute('web'),
								url: retailerUrl,
								distance: parseFloat(marker.getAttribute('distance'))
							};

							markerData.json = JSON.stringify(markerData);

							if (i < SEARCH_COUNT) {
								_this4.viewModel.retailers.push(markerData);
							}
						}
					});
				}
			}
		}, {
			key: 'geolocate',
			value: function geolocate() {
				var _this5 = this;

				if (navigator.geolocation) {
					navigator.geolocation.getCurrentPosition(function (position) {
						_this5.searchLocationsNear(new _shim.GoogleMapsApi.LatLng(position.coords.latitude, position.coords.longitude));
					}, function () {
						_this5.app.showError(_this5.app.getText('errors.defaultTitle'), $.elem('p').text(_this5.app.getText('errors.noGeolocationErrorMessage')));
					});
				}
			}
		}, {
			key: 'showRetailerInfo',
			value: function showRetailerInfo(retailer) {
				var $message = $($('[data-id=' + retailer.id + ']').find('.modal-content.full-info:first').html());
				this.app.showExplanation(this.app.getText('retailers.infoTitle'), $message);
			}
		}]);

		return RetailersPart;
	}(_part.Part);
});
