define('util/event',['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.pointerEventToXY = pointerEventToXY;
	/**
  * Helpers for event handling and transformation
  **/

	/**
  * Retrieves and returns x;y of a pointer event on the document
  **/
	function pointerEventToXY(e) {
		var out = { x: 0, y: 0 };

		if (e.type === 'touchstart' || e.type === 'touchmove' || e.type === 'touchend' || e.type === 'touchcancel') {
			var touch = e.originalEvent.touches[0] || e.originalEvent.changedTouches[0];
			out.x = touch.pageX;
			out.y = touch.pageY;
		} else if (e.type === 'mousedown' || e.type === 'mouseup' || e.type === 'mousemove' || e.type === 'mouseover' || e.type === 'mouseout' || e.type == 'mouseenter' || e.type == 'mouseleave') {
			out.x = e.pageX;
			out.y = e.pageY;
		}

		return out;
	}
});
