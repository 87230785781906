define('app/components/yesno',['exports', 'lib/shim', 'util/set', 'app/abstract/component'], function (exports, _shim, _set, _component) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.YesNoComponent = undefined;

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
				arr2[i] = arr[i];
			}

			return arr2;
		} else {
			return Array.from(arr);
		}
	}

	function _classCallCheck(instance, Constructor) {
		if (!(instance instanceof Constructor)) {
			throw new TypeError("Cannot call a class as a function");
		}
	}

	function _possibleConstructorReturn(self, call) {
		if (!self) {
			throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
		}

		return call && (typeof call === "object" || typeof call === "function") ? call : self;
	}

	var _get = function get(object, property, receiver) {
		if (object === null) object = Function.prototype;
		var desc = Object.getOwnPropertyDescriptor(object, property);

		if (desc === undefined) {
			var parent = Object.getPrototypeOf(object);

			if (parent === null) {
				return undefined;
			} else {
				return get(parent, property, receiver);
			}
		} else if ("value" in desc) {
			return desc.value;
		} else {
			var getter = desc.get;

			if (getter === undefined) {
				return undefined;
			}

			return getter.call(receiver);
		}
	};

	var _createClass = function () {
		function defineProperties(target, props) {
			for (var i = 0; i < props.length; i++) {
				var descriptor = props[i];
				descriptor.enumerable = descriptor.enumerable || false;
				descriptor.configurable = true;
				if ("value" in descriptor) descriptor.writable = true;
				Object.defineProperty(target, descriptor.key, descriptor);
			}
		}

		return function (Constructor, protoProps, staticProps) {
			if (protoProps) defineProperties(Constructor.prototype, protoProps);
			if (staticProps) defineProperties(Constructor, staticProps);
			return Constructor;
		};
	}();

	function _inherits(subClass, superClass) {
		if (typeof superClass !== "function" && superClass !== null) {
			throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
		}

		subClass.prototype = Object.create(superClass && superClass.prototype, {
			constructor: {
				value: subClass,
				enumerable: false,
				writable: true,
				configurable: true
			}
		});
		if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
	}

	//###[ CONSTANTS ]######################################################################################################

	var TEMPLATE_SELECTOR = '#yesno-component-template';

	//###[ SCHEMA ]#########################################################################################################

	var SCHEMA = {
		type: 'object',
		properties: {
			'model': {
				type: 'object',
				properties: {
					'options': {
						type: 'array',
						items: {
							// is validated by subcomponents/selectelement
							type: 'object'
						}
					}
				}
			}
		}
	};

	//###[ YESNOCOMPONENT ]#################################################################################################

	/**
  * A simple Yes/No True/False On/Off component, indicating a single selection or approval of something.
  **/

	var YesNoComponent = exports.YesNoComponent = function (_Component) {
		_inherits(YesNoComponent, _Component);

		_createClass(YesNoComponent, null, [{
			key: 'register',


			//###( STATIC )###

			value: function register() {
				_component.Component.register('YesNoComponent', YesNoComponent, TEMPLATE_SELECTOR);
			}

			//###( CLASS )###

		}]);

		function YesNoComponent(params) {
			_classCallCheck(this, YesNoComponent);

			// strip possible observables
			params = ko.viewmodel.toModel(params);
			var paramsInspection = _shim.SchemaInspector.validate(SCHEMA, params);
			$.assert(paramsInspection.valid, 'YesNoComponent.constructor | params does not fit schema (' + paramsInspection.format() + ')');

			var _this = _possibleConstructorReturn(this, (YesNoComponent.__proto__ || Object.getPrototypeOf(YesNoComponent)).call(this, $.extend(true, {
				model: {
					// list of options, should be two
					options: []
				}
			}, params)));

			$.assert(_this.viewModel.options().length == 2, 'YesNoComponent constructor | number of options must be two');

			// currently selected value
			_this.viewModel.selected = ko.observable('');

			//^^^
			_this.viewModel.description = ko.observable('');

			//^^^
			_this.viewModel.internalValue = ko.computed({
				read: function read() {
					return _this.viewModel.selected();
				},
				write: function write(value) {
					var option = _.find(_this.viewModel.options(), function (opt) {
						return opt.value() == value;
					});
					if ($.isSet(option)) {
						_this.setActiveOption(option);
					}
				}
			}).extend({ notify: 'always' });
			_this._addDisposable(_this.viewModel.internalValue);

			_this.setActiveOption();
			return _this;
		}

		_createClass(YesNoComponent, [{
			key: 'dispose',
			value: function dispose() {
				_get(YesNoComponent.prototype.__proto__ || Object.getPrototypeOf(YesNoComponent.prototype), 'dispose', this).call(this);
			}

			//###( SETTER )###

			/**
    * Selects and highlights the currently active option. If no value and/or description are given this
    * method just refreshes the involved observables based on the currently set internalValue, to
    * force a lagging template to refresh.
    **/

		}, {
			key: 'setActiveOption',
			value: function setActiveOption(value, description) {
				var _this2 = this;

				var option = null;
				if (!$.isSet(value) || !$.isSet(description)) {
					option = _.find(this.viewModel.options(), function (opt) {
						return opt.value() == _this2.viewModel.internalValue();
					});
				} else {
					option = _.find(this.viewModel.options(), function (opt) {
						return opt.value() == value;
					});
				}

				if ($.isSet(option)) {
					this.viewModel.description(option.title());
					this.viewModel.selected(option.value());
				}
			}

			//###( FUNCTIONALITY )###

			//^^^

		}, {
			key: 'initAllTags',
			value: function initAllTags() {
				var allTags = new Set();

				_.each(this.viewModel.options(), function (option) {
					allTags = (0, _set.union)(allTags, new Set(option.tags()));
				});

				this.viewModel.allTags = ko.observableArray([].concat(_toConsumableArray(allTags)));
			}

			//^^^

		}, {
			key: 'buildActiveTags',
			value: function buildActiveTags(value) {
				var _this3 = this;

				value = $.orDefault(value, this.viewModel.internalValue(), 'string');

				var values = _.map(_.compact(value.split(';')), function (val) {
					return _.trim(val);
				}),
				    activeTags = new Set();

				_.each(values, function (val) {
					_.each(_this3.viewModel.options(), function (option) {
						if (val === option.value()) {
							activeTags = (0, _set.union)(activeTags, new Set(option.tags()));
						}
					});
				});

				this.viewModel.activeTags = ko.observableArray([].concat(_toConsumableArray(activeTags)));
			}
		}]);

		return YesNoComponent;
	}(_component.Component);
});
