define('lib/shim',["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  // keep these references, they might be directly referenced by other plugins
  var knockout = exports.knockout = window.ko;
  var jQuery = exports.jQuery = window.$;
  var lodash = exports.lodash = window._;

  // remove other references from window, to force manual import
  var SchemaInspector = exports.SchemaInspector = window.SchemaInspector;
  delete window.SchemaInspector;

  var bootstrapDialog = exports.bootstrapDialog = window.BootstrapDialog;
  delete window.BootstrapDialog;

  var picturefill = exports.picturefill = window.picturefill;
  delete window.picturefill;

  var GoogleMapsApi = exports.GoogleMapsApi = window.google.maps;
  // this does not work, loaded scripts expect this var to be present!
  //delete window.google.maps;
});
