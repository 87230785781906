define('app/widgets/info',['exports', 'lib/shim', 'app/abstract/widget'], function (exports, _shim, _widget) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.InfoWidget = undefined;

	function _classCallCheck(instance, Constructor) {
		if (!(instance instanceof Constructor)) {
			throw new TypeError("Cannot call a class as a function");
		}
	}

	function _possibleConstructorReturn(self, call) {
		if (!self) {
			throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
		}

		return call && (typeof call === "object" || typeof call === "function") ? call : self;
	}

	var _get = function get(object, property, receiver) {
		if (object === null) object = Function.prototype;
		var desc = Object.getOwnPropertyDescriptor(object, property);

		if (desc === undefined) {
			var parent = Object.getPrototypeOf(object);

			if (parent === null) {
				return undefined;
			} else {
				return get(parent, property, receiver);
			}
		} else if ("value" in desc) {
			return desc.value;
		} else {
			var getter = desc.get;

			if (getter === undefined) {
				return undefined;
			}

			return getter.call(receiver);
		}
	};

	var _createClass = function () {
		function defineProperties(target, props) {
			for (var i = 0; i < props.length; i++) {
				var descriptor = props[i];
				descriptor.enumerable = descriptor.enumerable || false;
				descriptor.configurable = true;
				if ("value" in descriptor) descriptor.writable = true;
				Object.defineProperty(target, descriptor.key, descriptor);
			}
		}

		return function (Constructor, protoProps, staticProps) {
			if (protoProps) defineProperties(Constructor.prototype, protoProps);
			if (staticProps) defineProperties(Constructor, staticProps);
			return Constructor;
		};
	}();

	function _inherits(subClass, superClass) {
		if (typeof superClass !== "function" && superClass !== null) {
			throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
		}

		subClass.prototype = Object.create(superClass && superClass.prototype, {
			constructor: {
				value: subClass,
				enumerable: false,
				writable: true,
				configurable: true
			}
		});
		if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
	}

	//###[ CONSTANTS ]######################################################################################################

	var TEMPLATE_SELECTOR = '#info-widget-template';

	//###[ SCHEMA ]#########################################################################################################

	var SCHEMA = {
		type: 'object',
		properties: {
			'model': {
				type: 'object',
				properties: {
					'iconCharacter': {
						optional: true,
						type: 'string',
						minLength: 1,
						maxLength: 1
					},

					'title': {
						type: 'string',
						minLength: 1
					},

					'text': {
						optional: true,
						type: 'string'
					},

					'image': {
						// is validated by widget/image
						optional: true,
						type: 'object'
					}
				}
			}
		}
	};

	//###[ INFOWIDGET ]#####################################################################################################

	/**
  * Default widget for adding additional information to an element. Consists a a clickable button, that opens a modal
  * containing all relevant information.
  **/

	var InfoWidget = exports.InfoWidget = function (_Widget) {
		_inherits(InfoWidget, _Widget);

		_createClass(InfoWidget, null, [{
			key: 'register',


			//###( STATIC )###

			value: function register() {
				_widget.Widget.register('InfoWidget', InfoWidget, TEMPLATE_SELECTOR);
			}

			//###( CLASS )###

		}]);

		function InfoWidget(params) {
			_classCallCheck(this, InfoWidget);

			// strip possible observables
			params = ko.viewmodel.toModel(params);
			var paramsInspection = _shim.SchemaInspector.validate(SCHEMA, params);
			$.assert(paramsInspection.valid, 'InfoWidget.constructor | params does not fit schema (' + paramsInspection.format() + ')');

			return _possibleConstructorReturn(this, (InfoWidget.__proto__ || Object.getPrototypeOf(InfoWidget)).call(this, $.extend(true, {
				model: {
					// the character to display in the info bubble, which opens the modal
					iconCharacter: '',

					// modal title of the info overlay
					title: '',

					// explanation text in the info overlay
					text: '',

					// image for the info overlay
					image: undefined
				}
			}, params)));
		}

		_createClass(InfoWidget, [{
			key: 'dispose',
			value: function dispose() {
				_get(InfoWidget.prototype.__proto__ || Object.getPrototypeOf(InfoWidget.prototype), 'dispose', this).call(this);
			}

			//###( FUNCTIONALITY )###

			/**
    * trigger display of the info overlay
   	 **/

		}, {
			key: 'showModal',
			value: function showModal() {
				var $message = $($('[data-id=' + this.viewModel.id() + ']').find('.modal-content:first').html());
				this.app.showExplanation(this.viewModel.title(), $message);
			}
		}]);

		return InfoWidget;
	}(_widget.Widget);
});
