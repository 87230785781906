define('app/app',['exports', 'lib/shim', 'app/schemas/requestanswerschemas', 'util/set', 'util/event', 'util/behaviour', 'app/abstract/viewmodel', 'app/parts/stepnavigation', 'app/parts/pagenavigation', 'app/parts/retailers', 'app/parts/currentconfiguration', 'app/parts/cookielayer', 'app/step', 'app/components/yesno', 'app/components/listselect', 'app/components/dimensions', 'app/subcomponents/selectelement', 'app/subcomponents/optionasymmetricselectelement', 'app/subcomponents/optionsymmetricselectelement', 'app/subcomponents/optionyesno', 'app/widgets/image', 'app/widgets/info', 'app/finalize/customerinquiry'], function (exports, _shim, _requestanswerschemas, _set, _event, _behaviour, _viewmodel, _stepnavigation, _pagenavigation, _retailers, _currentconfiguration, _cookielayer, _step, _yesno, _listselect, _dimensions, _selectelement, _optionasymmetricselectelement, _optionsymmetricselectelement, _optionyesno, _image, _info, _customerinquiry) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.App = undefined;

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
				arr2[i] = arr[i];
			}

			return arr2;
		} else {
			return Array.from(arr);
		}
	}

	function _classCallCheck(instance, Constructor) {
		if (!(instance instanceof Constructor)) {
			throw new TypeError("Cannot call a class as a function");
		}
	}

	function _possibleConstructorReturn(self, call) {
		if (!self) {
			throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
		}

		return call && (typeof call === "object" || typeof call === "function") ? call : self;
	}

	var _get = function get(object, property, receiver) {
		if (object === null) object = Function.prototype;
		var desc = Object.getOwnPropertyDescriptor(object, property);

		if (desc === undefined) {
			var parent = Object.getPrototypeOf(object);

			if (parent === null) {
				return undefined;
			} else {
				return get(parent, property, receiver);
			}
		} else if ("value" in desc) {
			return desc.value;
		} else {
			var getter = desc.get;

			if (getter === undefined) {
				return undefined;
			}

			return getter.call(receiver);
		}
	};

	var _createClass = function () {
		function defineProperties(target, props) {
			for (var i = 0; i < props.length; i++) {
				var descriptor = props[i];
				descriptor.enumerable = descriptor.enumerable || false;
				descriptor.configurable = true;
				if ("value" in descriptor) descriptor.writable = true;
				Object.defineProperty(target, descriptor.key, descriptor);
			}
		}

		return function (Constructor, protoProps, staticProps) {
			if (protoProps) defineProperties(Constructor.prototype, protoProps);
			if (staticProps) defineProperties(Constructor, staticProps);
			return Constructor;
		};
	}();

	function _inherits(subClass, superClass) {
		if (typeof superClass !== "function" && superClass !== null) {
			throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
		}

		subClass.prototype = Object.create(superClass && superClass.prototype, {
			constructor: {
				value: subClass,
				enumerable: false,
				writable: true,
				configurable: true
			}
		});
		if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
	}

	//###[ SCHEMA ]########################################################################################################

	var SCHEMA = {
		type: 'object',
		properties: {
			'texts': {
				type: 'object',
				properties: {
					'*': {
						type: 'object',
						properties: {
							// custom named translation dict entries, holding a string each
							'*': { type: 'string' }
						}
					}
				}
			},

			'header': {
				// contents are further validated by parts (StepNavigation and PageNavigation)
				type: 'object'
			},

			'moreInformation': {
				type: 'object',
				properties: {
					'page': _pagenavigation.PAGE_SCHEMA
				}
			},

			'partlistExplanation': {
				type: 'object',
				properties: {
					'page': _pagenavigation.PAGE_SCHEMA
				}
			},

			'inquiry': {
				type: 'object',
				properties: {
					'conditionsPage': _pagenavigation.PAGE_SCHEMA,
					'dataSecurityPage': _pagenavigation.PAGE_SCHEMA
				}
			},

			'steps': {
				// contents are further validated by each rendered step
				type: 'array',
				items: {
					type: 'object'
				}
			}
		}
	};

	//###[ APP ]############################################################################################################

	/**
  * Root object of the application. Manages global dictionaries of values and tags. Is referenced by underlying
  * objects, to manage values and tags.
  **/

	var App = exports.App = function (_ViewModel) {
		_inherits(App, _ViewModel);

		_createClass(App, null, [{
			key: 'registerComponents',


			//###( STATIC )###

			value: function registerComponents() {
				_stepnavigation.StepNavigationPart.register();
				_pagenavigation.PageNavigationPart.register();
				_currentconfiguration.CurrentConfigurationPart.register();
				_retailers.RetailersPart.register();
				_cookielayer.CookieLayerPart.register();

				_image.ImageWidget.register();
				_info.InfoWidget.register();

				_selectelement.SelectElementSubComponent.register();
				_optionasymmetricselectelement.OptionAsymmetricSelectElementSubComponent.register();
				_optionsymmetricselectelement.OptionSymmetricSelectElementSubComponent.register();
				_optionyesno.OptionYesNoSubComponent.register();

				_yesno.YesNoComponent.register();
				_listselect.ListSelectComponent.register();
				_dimensions.DimensionsComponent.register();

				_step.Step.register();
			}

			//###( CLASS )###

		}]);

		function App() {
			_classCallCheck(this, App);

			var _this = _possibleConstructorReturn(this, (App.__proto__ || Object.getPrototypeOf(App)).call(this));

			_this.viewModel = {};

			// the DOLLE_CONFIG from the base tempate
			_this.viewModel.config = {};

			// all currently selected tags, based on selected component values
			_this.viewModel.tags = ko.observableArray([]);

			// dictionary of all currently selected values of components, split by step on the first level, values are
			// identified by given names from the config
			_this.viewModel.stepValues = {};

			// we need this to manually notify subscribers of a changed tag config
			_this.viewModel.tagsChanged = ko.observable();

			// allow application part to directly react to any changes in the stepValue dict
			_this.viewModel.stepValuesChanged = ko.observable();

			// remember the last user interaction coordinates
			_this.viewModel.lastInteractionCoordinates = ko.observable('');
			$('body').on('mousedown.lastappinteraction touchstart.lastappinteraction', function (e) {
				var coords = (0, _event.pointerEventToXY)(e);
				_this.viewModel.lastInteractionCoordinates(coords.x + ';' + coords.y);
			});

			// trace the current step we're editing
			_this.viewModel.currentStepNumber = ko.observable(1);

			// trace if the current config could be considered complete
			_this.viewModel.configIsComplete = ko.observable(false);
			_this._addDisposable(_this.viewModel.configIsComplete.subscribe(function (newValue) {
				if (newValue === true) {
					_this.scrollToCompleteHint();
				}
			}));

			// define if we still are in the configuration part or already are in the final dialogue
			_this.viewModel.finalizeConfig = ko.observable(false);

			// keep scrollTops to reset when changing between config and finalize dialogue
			_this.scrollTops = {
				config: 0,
				finalize: 0
			};

			// if user already reached finalize step, keep generated token, for case he returns, so we can keep
			// using same token
			_this.currentCustomerInquiryTokenObj = null;

			// prepare the app to be able to register callbacks on resize for subcomponents and parts to avoid multiple
			// resize handlers for performance reasons
			_this.setupGlobalResizeCallbacks();

			// prepare the app to receive postmessages from embedded iframes
			_this.setupPostMessageHandling();

			// define and start autonomous general header behaviours
			_this._handleHeader();

			// define and start autonomous general footer behaviours
			_this._handleFooter();

			_.defer(function () {
				(0, _behaviour.makeCollapsible)($('#app main > .finalize').find('.collapsible'));

				$('#app').addClass('app-ready').triggerHandler('ready.app');

				$(window).one('load', function () {
					$.schedule(250, function () {
						$('html, body').scrollTop(0);
					});
				});
			});
			return _this;
		}

		_createClass(App, [{
			key: 'dispose',
			value: function dispose() {
				$('#app').off('mousedown.lastappinteraction touchstart.lastappinteraction');
				this.teardownGlobalResizeCallbacks();

				_get(App.prototype.__proto__ || Object.getPrototypeOf(App.prototype), 'dispose', this).call(this);
			}

			//###( DEV )###

		}, {
			key: 'mockCompleteConfig',
			value: function mockCompleteConfig() {
				if (this.getConfigItem('debug')) {
					this.viewModel.stepValues = JSON.parse('{"step1-verwendungszweck":{"verwendungszweck":{"value":"gewaechshaus","description":"Gewächshaus"}},"step2-plattentyp":{"plattentyp":{"value":"lichtplatten","description":"Lichtplatten"}},"step3-plattentypvariante":{"plattenmaterial":{"value":"pvc","description":"PVC Lichtplatte"}},"step4-farbauswahl":{"farbe":{"value":"glashell","description":"Glashell"}},"step5-masseingabe":{"masse":{"value":"automatisch|3333x3333|4*1106x3333(20)","description":"4 Platte(n) à 1106mm x 3333mm"}},"step6-unterkonstruktion":{"unterkonstruktionmaterial":{"value":"metal","description":"Metall"},"unterkonstruktionprofil":{"value":"komplett","description":"Alu Komplettprofil"},"abdeckprofiloption":{"value":"nein","description":"Nein"}}}');
					this.MOCK_PLATECONFIG_REX = new RegExp($.strReplace('PREFIXES', 'automatisch', _dimensions.PLATECONFIG_REX.source));
					this.viewModel.tags = ko.observableArray(["gewaechshaus", "verwendungszweck", "lichtplatten", "plattentyp", "lichtplatten-pvc", "plattenmaterial", "plattentypvariante", "farbauswahl", "farbauswahl-glashell", "lichtplatten-automatisch", "masseingabe", "unterkonstruktion", "unterkonstruktion-metall", "unterkonstruktionprofil", "unterkonstruktionprofil-komplett", "abdeckprofiloption", "abdeckprofiloption-nein"]);
					this.viewModel.currentStepNumber = $.objectLength(this.viewModel.stepValues);
					this.viewModel.configIsComplete(true);
					this.openFinalizeConfig();
				}
			}

			//###( SETTER )###

		}, {
			key: 'setConfig',
			value: function setConfig(config) {
				var _this2 = this;

				var configInspection = _shim.SchemaInspector.validate(SCHEMA, config);
				$.assert(configInspection.valid, 'App.setConfig | config does not fit schema (' + configInspection.format() + ')');

				this.viewModel.config = ko.viewmodel.fromModel(config);
				this.collectAllNeededTags();

				if (this.getConfigItem('debug')) {
					$(document).off('keydown.devmock').on('keydown.devmock', function (e) {
						// CTRL+ALT+F
						if (e.altKey && e.keyCode == 70) {
							$(document).off('keydown.devmock');
							_this2.mockCompleteConfig();
						}
					});
				}
			}
		}, {
			key: 'setStepValues',
			value: function setStepValues(key, values) {
				var valuesInspection = _shim.SchemaInspector.validate(_step.VALUES_SCHEMA, values);
				$.assert(valuesInspection.valid, 'App.setStepValues | values do not fit schema (' + valuesInspection.format() + ')');

				this.viewModel.stepValues['' + key] = values;
				this.updateStatusInfo();
				this.viewModel.stepValuesChanged.notifySubscribers();
			}
		}, {
			key: 'updateStepValues',
			value: function updateStepValues(key, values) {
				$.assert($.isSet(this.viewModel.stepValues['' + key]), 'App.updateStepValues | key not defined, cannot update');
				var valuesInspection = _shim.SchemaInspector.validate(_step.VALUES_SCHEMA, values);
				$.assert(valuesInspection.valid, 'App.updateStepValues | values do not fit schema (' + valuesInspection.format() + ')');

				this.viewModel.stepValues['' + key] = values;
				this.updateStatusInfo();
				this.viewModel.stepValuesChanged.notifySubscribers();
				$.log(this.viewModel.stepValues['' + key]);
			}
		}, {
			key: 'removeStepValues',
			value: function removeStepValues(key) {
				if ($.isSet(this.viewModel.stepValues['' + key])) {
					delete this.viewModel.stepValues['' + key];
					this.updateStatusInfo();
					this.viewModel.stepValuesChanged.notifySubscribers();
				}
			}
		}, {
			key: 'addTags',
			value: function addTags(tags) {
				if (!$.isArray(tags)) {
					if ($.isSet(tags.size)) {
						tags = [].concat(_toConsumableArray(tags));
					} else {
						tags = [tags];
					}
				}

				var set = new Set(this.viewModel.tags());

				_.each(tags, function (tag) {
					set.add('' + tag);
				});

				this.viewModel.tags = ko.observableArray([].concat(_toConsumableArray(set)));
				this.viewModel.tagsChanged.notifySubscribers();

				$.log(this.viewModel.tags());
			}
		}, {
			key: 'removeTags',
			value: function removeTags(tags) {
				if (!$.isArray(tags)) {
					if ($.isSet(tags.size)) {
						tags = [].concat(_toConsumableArray(tags));
					} else {
						tags = [tags];
					}
				}

				var set = new Set(this.viewModel.tags());

				_.each(tags, function (tag) {
					set.delete('' + tag);
				});

				this.viewModel.tags = ko.observableArray([].concat(_toConsumableArray(set)));
				this.viewModel.tagsChanged.notifySubscribers();

				$.log(this.viewModel.tags());
			}

			/**
    * Update et of all tags appearing anywhere in the config in a "neededTags"-attribute.
    * This set defines the need for a user confirmation on a value change on a widget.
    * If the tags are in the set, something might get lost.
    **/

		}, {
			key: 'collectAllNeededTags',
			value: function collectAllNeededTags() {
				var _this3 = this;

				this.allNeededTags = new Set([]);

				var fSearch = function fSearch(obj, key) {
					if (_.indexOf(['neededTags'], key) < 0) {
						if (_.isPlainObject(obj) || _.isFunction(obj) && _.isArray(obj())) {
							_.each(_.isPlainObject(obj) ? obj : obj(), function (sub, subKey) {
								fSearch(sub, subKey);
							});
						}
					} else {
						if (_.isPlainObject(obj) || _.isFunction(obj) && _.isArray(obj())) {
							_.each(_.isPlainObject(obj) ? obj : obj(), function (tag) {
								_this3.allNeededTags.add('' + tag);
							});
						}
					}
				};

				fSearch(this.viewModel.config);
			}

			//###( GETTER )###

		}, {
			key: 'getStepValues',
			value: function getStepValues(key) {
				if ($.isSet(key)) {
					return $.isSet(this.viewModel.stepValues['' + key]) ? this.viewModel.stepValues['' + key] : null;
				} else {
					return this.viewModel.stepValues;
				}
			}

			/**
    * Compile general information about the app's steps for use in step displays like step nav and current config.
    **/

		}, {
			key: 'getStepInfo',
			value: function getStepInfo() {
				var stepInfo = [];

				_.each(this.viewModel.config.steps(), function (step, index) {
					stepInfo.push({
						number: index + 1,
						name: step.name(),
						headline: step.headline()
					});
				});

				return stepInfo;
			}
		}, {
			key: 'getTags',
			value: function getTags() {
				return this.viewModel.tags();
			}
		}, {
			key: 'getAllNeededTags',
			value: function getAllNeededTags() {
				return this.allNeededTags;
			}
		}, {
			key: 'getLastInteractionCoordinates',
			value: function getLastInteractionCoordinates() {
				var coords = this.viewModel.lastInteractionCoordinates().split(';');

				return {
					x: parseInt(coords[0], 10),
					y: parseInt(coords[1], 10)
				};
			}
		}, {
			key: 'getCurrentStepNumber',
			value: function getCurrentStepNumber() {
				return this.viewModel.currentStepNumber();
			}

			/**
    * Return a text from the config's text dict by object path like "foo.bar.foobar"
    **/

		}, {
			key: 'getText',
			value: function getText(path) {
				var text = _.get(this.viewModel.config.texts, path, '');
				return _.isFunction(text) ? text() : text;
			}

			/**
    * Return any config value/item from the apps config identified by an object path like "foo.bar.foobar"
    **/

		}, {
			key: 'getConfigItem',
			value: function getConfigItem(path) {
				var item = _.get(this.viewModel.config, path, null);
				return _.isFunction(item) ? item() : item;
			}

			//###( QUESTIONS )###

			/**
    * Check if a list of tags is currently present in the app's global pool of tags.
    **/

		}, {
			key: 'tagsArePresent',
			value: function tagsArePresent(tags) {
				tags = $.orDefault(tags, [], 'array');

				return (0, _set.difference)(new Set(tags), new Set(this.viewModel.tags())).size === 0;
			}

			/**
    * Factory for ko.computeds based on tagsArePresent with a fixed set of Tags. Widely used for visibility.
    **/

		}, {
			key: 'cTagsArePresent',
			value: function cTagsArePresent(tags) {
				var _this4 = this;

				tags = $.orDefault(tags, [], 'array');

				var newComputed = ko.computed(function () {
					_this4.viewModel.tagsChanged();
					return _this4.tagsArePresent(tags);
				});
				this._addDisposable(newComputed);

				return newComputed;
			}

			//###( FUNCTIONALITY )###

			/**
    * Sets the DOM-element to apply the app's viewmodel on, normally this should be $('body')
    **/

		}, {
			key: 'applyToElement',
			value: function applyToElement($element) {
				ko.applyBindings(this, $element.first().oo());
			}

			/**
    * Display an explanation modal.
    **/

		}, {
			key: 'showExplanation',
			value: function showExplanation(title, $message) {
				if (!$.isA($message, 'object')) {
					$message = $('' + $message).html();
				}

				_shim.bootstrapDialog.show({
					cssClass: 'info',
					title: title,
					message: $message,
					buttons: [{
						label: this.getText('explanation.closeButton'),
						cssClass: 'arrow',
						action: function action(modal) {
							modal.close();
						}
					}]
				});
			}

			/**
    * Display a modal containing mark-upped content, essentially being a page of editorial content.
    **/

		}, {
			key: 'showPage',
			value: function showPage(page, $message) {
				if (!$.isA($message, 'object')) {
					$message = $($('' + $message).html());
				}

				$message.find('a[href]').attr('target', '_blank');

				_shim.bootstrapDialog.show({
					cssClass: 'page',
					title: $.isFunction(page.title) && !$.isEmpty(page.title()) ? page.title() : '',
					message: $message,
					buttons: [{
						label: this.getText('page.closeButton'),
						cssClass: 'arrow',
						action: function action(modal) {
							modal.close();
						}
					}]
				});
			}

			/**
    * Display a modal containing an error message.
    **/

		}, {
			key: 'showError',
			value: function showError(title, $message, needsReload, cssClass) {
				needsReload = $.orDefault(needsReload, false, 'bool');
				cssClass = $.orDefault(cssClass, 'error', 'string');

				if (!$.isA($message, 'object')) {
					$message = $('' + $message).html();
				}

				_shim.bootstrapDialog.show({
					cssClass: cssClass,
					title: title,
					message: $message,
					buttons: [{
						label: needsReload ? this.getText('errors.reloadButton') : this.getText('errors.closeButton'),
						cssClass: 'arrow',
						action: function action(modal) {
							if (needsReload) {
								$.reload(true);
							} else {
								modal.close();
							}
						}
					}],
					onhide: function onhide(modal) {
						return !needsReload;
					}
				});
			}
		}, {
			key: 'showLoadingOverlay',
			value: function showLoadingOverlay(message) {
				message = $.orDefault(message, this.getText('loadingOverlay.standard'), 'string');

				$('#loadingoverlay').children('.message').text(message).end().removeClass('hidden').fadeIn(250);
			}
		}, {
			key: 'hideLoadingOverlay',
			value: function hideLoadingOverlay() {
				$('#loadingoverlay').children('.message').text('').end().fadeOut(250);
			}

			/**
    * Show the final message after submitting the inquiry form
    **/

		}, {
			key: 'showFinalMessage',
			value: function showFinalMessage() {
				this.showError(this.getText('finalMessage.title'), $.elem('p').text(this.getText('finalMessage.copytext')), true, 'final-message');
			}

			/**
    * Update global status variables like step number and completion status.
    **/

		}, {
			key: 'updateStatusInfo',
			value: function updateStatusInfo() {
				var _this5 = this;

				var currentStepNumber = null;

				_.each(this.viewModel.config.steps(), function (step, index) {
					if (!$.isSet(_this5.getStepValues(step.name()))) {
						currentStepNumber = index;
						return false;
					}
				});
				currentStepNumber = currentStepNumber === null ? this.viewModel.config.steps().length : currentStepNumber;
				currentStepNumber = currentStepNumber < 1 ? 1 : currentStepNumber;

				this.viewModel.currentStepNumber(currentStepNumber);

				var lastStep = _.last(this.viewModel.config.steps()),
				    lastStepValues = _.values(this.getStepValues(lastStep.name()));

				this.viewModel.configIsComplete(this.viewModel.config.steps().length == currentStepNumber && !$.isSet(_.findKey(lastStepValues, { value: '' })));
			}

			/**
    * Scroll to the last element of the configutator being the complete hint with a button to get to the finalize step.
    **/

		}, {
			key: 'scrollToCompleteHint',
			value: function scrollToCompleteHint() {
				$.schedule(100, function () {
					var innerHeight = window.innerHeight || $(window).height();
					$('#app .configurator .complete-hint').first().scrollTo($.noop, 500, Math.round(innerHeight / 3), true);
				});
			}

			/**
    * Switch from the configurator to the finalize step if permittable.
    **/

		}, {
			key: 'openFinalizeConfig',
			value: function openFinalizeConfig() {
				var _this6 = this;

				if (this.viewModel.configIsComplete()) {
					$.log(this.viewModel.stepValues, JSON.stringify(this.viewModel.stepValues));
					var cutOptions = null,
					    plateConfigRex = $.isSet(this.MOCK_PLATECONFIG_REX) ? this.MOCK_PLATECONFIG_REX : _dimensions.PLATECONFIG_REX;
					_.each(this.viewModel.stepValues, function (step) {
						_.each(step, function (config) {
							if (plateConfigRex.test(config.value)) {
								cutOptions = config.value;
								return false;
							}
						});

						if ($.isSet(cutOptions)) {
							return false;
						}
					});
					$.assert($.isSet(cutOptions), 'App.openFinalizeConfig | could not find cut options in current values');

					var cutOptionParts = cutOptions.split('|'),
					    roofDims = cutOptionParts[1].split('x'),
					    plateConfigs = cutOptionParts[2].split(';');

					var data = {
						customerInquiryToken: null,
						dimensions: {
							width: parseInt(roofDims[0], 10),
							length: parseInt(roofDims[1], 10)
						},
						plates: [],
						tags: this.getTags()
					};

					_.each(plateConfigs, function (plateConfig) {
						var configParts = $.strReplace(['x', '*', '(', ')'], '_', plateConfig).split('_');

						data.plates.push({
							count: parseInt(configParts[0], 10),
							width: parseInt(configParts[1], 10),
							length: parseInt(configParts[2], 10),
							id: parseInt(configParts[3], 10)
						});
					});

					if (!$.isSet(this.currentCustomerInquiryTokenObj)) {
						$.getJSON('/inquiries/token/').done(function (customerInquiryTokenJson) {
							var answerInspection = _shim.SchemaInspector.validate(_requestanswerschemas.CUSTOMERINQUIRYTOKEN_ANSWER_SCHEMA, customerInquiryTokenJson);

							if (answerInspection.valid) {
								_this6.currentCustomerInquiryTokenObj = customerInquiryTokenJson.customerInquiry;
								data.customerInquiryToken = customerInquiryTokenJson.customerInquiry.token;

								_this6.finishOpenFinalizeConfig(data);
							} else {
								$.warn('App.openFinalizeConfig | customerinquirytoken answer does not fit schema (' + answerInspection.format() + ')');

								_this6.showError(_this6.getText('errors.defaultTitle'), $.elem('p').text(_this6.getText('errors.validationErrorMessage')), true);
							}
						}).fail(function () {
							_this6.showError(_this6.getText('errors.defaultTitle'), $.elem('p').text(_this6.getText('errors.serverCommunicationErrorMessage')), true);
						});
					} else {
						data.customerInquiryToken = this.currentCustomerInquiryTokenObj.token;
						this.finishOpenFinalizeConfig(data);
					}
				}
			}
		}, {
			key: 'finishOpenFinalizeConfig',
			value: function finishOpenFinalizeConfig(data) {
				var _this7 = this;

				$.ajax({
					url: '/parts/partlist-url/',
					type: 'POST',
					contentType: 'application/json',
					dataType: 'json',
					data: JSON.stringify(data),
					headers: {
						'X-CSRFToken': $.cookie('csrftoken')
					}
				}).done(function (partlistUrlJson) {
					var answerInspection = _shim.SchemaInspector.validate(_requestanswerschemas.PARTLIST_ANSWER_SCHEMA, partlistUrlJson);

					if (answerInspection.valid) {
						_this7.scrollTops.config = $(window).scrollTop();
						$(_this7).triggerHandler('openfinalize.app');

						_this7.viewModel.finalizeConfig(true);
						_.defer(function () {
							$('html, body').scrollTop(_this7.scrollTops.finalize);

							// brutally force iframe reload, since caching iframe requests is quite aggressive
							var $iframe = $('#partlist-frame');
							$iframe.attr('src', '');
							$iframe.attr('src', partlistUrlJson.partlist.url + '?cachebreaker=' + $.randomUUID(true));
							$iframe.nextAll('.no-list').find('.button.to-pdf').off('click').on('click', function () {
								$.redirect($.strReplace('//pdf', '/pdf', $.strFormat('{base}/pdf/', { base: partlistUrlJson.partlist.url })), null, null, null, '_blank');
							});

							var customerInquiry = new _customerinquiry.CustomerInquiry(_this7, partlistUrlJson, _this7.currentCustomerInquiryTokenObj);

							if ($.browserSupportsLocalStorage() && localStorage.getItem('has-seen-partlist-explanation') !== 'true' || !$.browserSupportsLocalStorage()) {
								_this7.showPage(_this7.getConfigItem('partlistExplanation.page'), '.finalize:first .partlist-explanation.modal-content:first');
								localStorage.setItem('has-seen-partlist-explanation', 'true');
							}
						});
					} else {
						$.warn('App.openFinalizeConfig | partlist answer does not fit schema (' + answerInspection.format() + ')');

						_this7.showError(_this7.getText('errors.defaultTitle'), $.elem('p').text(_this7.getText('errors.validationErrorMessage')), true);
					}
				}).fail(function () {
					_this7.showError(_this7.getText('errors.defaultTitle'), $.elem('p').text(_this7.getText('errors.serverCommunicationErrorMessage')), true);
				});
			}

			/**
    * Switch back from the finalize step to configurator.
    **/

		}, {
			key: 'closeFinalizeConfig',
			value: function closeFinalizeConfig() {
				var _this8 = this;

				this.scrollTops.finalize = $(window).scrollTop();
				$(this).triggerHandler('closefinalize.app');
				this.viewModel.finalizeConfig(false);
				_.defer(function () {
					$('html, body').scrollTop(_this8.scrollTops.config);
				});
			}

			//###( PROTECTED )###

		}, {
			key: '_handleHeader',
			value: function _handleHeader() {
				var lastScrollTop = 0,
				    $header = $('header');

				$(window).on('scroll.app, resize.app', function (e) {
					var st = $(this).scrollTop();
					if (st > 10) {
						$header.addClass('scrolled');
					} else {
						$header.removeClass('scrolled');
					}
					lastScrollTop = st;
				});
				$(window).triggerHandler('scroll.app');
			}
		}, {
			key: '_handleFooter',
			value: function _handleFooter() {}
			// nothing yet


			//###( GLOBAL EVENT HANDLING )###

		}, {
			key: 'setupGlobalResizeCallbacks',
			value: function setupGlobalResizeCallbacks() {
				this.globalResizeCallbacks = {};

				$(window).on('resize.app', _.throttle(function () {
					_.each(this.globalResizeCallbacks, function (callback) {
						callback();
					});
				}, 250));
			}
		}, {
			key: 'teardownGlobalResizeCallbacks',
			value: function teardownGlobalResizeCallbacks() {
				$(window).off('resize.app');
			}
		}, {
			key: 'registerGlobalResizeCallback',
			value: function registerGlobalResizeCallback(name, callback) {
				$.assert($.isFunction(callback), 'App.registerGlobalResizeCallback | callback is not a function');

				this.globalResizeCallbacks['' + name] = callback;
			}
		}, {
			key: 'unregisterGlobalResizeCallback',
			value: function unregisterGlobalResizeCallback(name) {
				delete this.globalResizeCallbacks['' + name];
			}
		}, {
			key: 'setupPostMessageHandling',
			value: function setupPostMessageHandling() {
				$(window).on('message', function (e) {
					e = e.originalEvent;
					if (e.origin == $.strReplace('::', ':', $.strFormat('{protocol}//{host}', window.location))) {
						if ($.exists('data.action', e) && $.exists('data.params', e)) {
							switch (e.data.action) {
								case 'resize-iframe':
									if ($.hasMembers(e.data.params, ['targetSelector', 'width', 'height'])) {
										$('iframe' + e.data.params.targetSelector).css({
											width: e.data.params.width,
											height: e.data.params.height
										});
									}
									break;
							}
						} else {
							$.warn('postMessage was called without name or params ' + e.data);
						}
					} else {
						$.warn('postMessage was tried from foreign domain ' + e.origin);
					}
				});
			}
		}]);

		return App;
	}(_viewmodel.ViewModel);
});
