define('app/components/listselect',['exports', 'lib/shim', 'util/set', 'app/abstract/component'], function (exports, _shim, _set, _component) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.ListSelectComponent = undefined;

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
				arr2[i] = arr[i];
			}

			return arr2;
		} else {
			return Array.from(arr);
		}
	}

	function _classCallCheck(instance, Constructor) {
		if (!(instance instanceof Constructor)) {
			throw new TypeError("Cannot call a class as a function");
		}
	}

	function _possibleConstructorReturn(self, call) {
		if (!self) {
			throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
		}

		return call && (typeof call === "object" || typeof call === "function") ? call : self;
	}

	var _get = function get(object, property, receiver) {
		if (object === null) object = Function.prototype;
		var desc = Object.getOwnPropertyDescriptor(object, property);

		if (desc === undefined) {
			var parent = Object.getPrototypeOf(object);

			if (parent === null) {
				return undefined;
			} else {
				return get(parent, property, receiver);
			}
		} else if ("value" in desc) {
			return desc.value;
		} else {
			var getter = desc.get;

			if (getter === undefined) {
				return undefined;
			}

			return getter.call(receiver);
		}
	};

	var _createClass = function () {
		function defineProperties(target, props) {
			for (var i = 0; i < props.length; i++) {
				var descriptor = props[i];
				descriptor.enumerable = descriptor.enumerable || false;
				descriptor.configurable = true;
				if ("value" in descriptor) descriptor.writable = true;
				Object.defineProperty(target, descriptor.key, descriptor);
			}
		}

		return function (Constructor, protoProps, staticProps) {
			if (protoProps) defineProperties(Constructor.prototype, protoProps);
			if (staticProps) defineProperties(Constructor, staticProps);
			return Constructor;
		};
	}();

	function _inherits(subClass, superClass) {
		if (typeof superClass !== "function" && superClass !== null) {
			throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
		}

		subClass.prototype = Object.create(superClass && superClass.prototype, {
			constructor: {
				value: subClass,
				enumerable: false,
				writable: true,
				configurable: true
			}
		});
		if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
	}

	//###[ CONSTANTS ]######################################################################################################

	var TEMPLATE_SELECTOR = '#listselect-component-template';

	//###[ SCHEMA ]#########################################################################################################

	var SCHEMA = {
		type: 'object',
		properties: {
			'model': {
				type: 'object',
				properties: {
					'elements': {
						type: 'array',
						items: {
							// is validated by subcomponents/selectelement
							type: 'object'
						}
					},

					'elementsPerRow': {
						optional: true,
						type: 'integer',
						gte: 1,
						lte: 4
					},

					'allowMultiple': {
						optional: true,
						type: 'boolean'
					}
				}
			}
		}
	};

	//###[ LISTSELECTCOMPONENT ]############################################################################################

	var ListSelectComponent = exports.ListSelectComponent = function (_Component) {
		_inherits(ListSelectComponent, _Component);

		_createClass(ListSelectComponent, null, [{
			key: 'register',


			//###( STATIC )###

			value: function register() {
				_component.Component.register('ListSelectComponent', ListSelectComponent, TEMPLATE_SELECTOR);
			}

			//###( CLASS )###

		}]);

		function ListSelectComponent(params) {
			_classCallCheck(this, ListSelectComponent);

			// strip possible observables
			params = ko.viewmodel.toModel(params);
			var paramsInspection = _shim.SchemaInspector.validate(SCHEMA, params);
			$.assert(paramsInspection.valid, 'ListSelectComponent.constructor | params does not fit schema (' + paramsInspection.format() + ')');

			// define if list allows selection of more than one element
			var _this = _possibleConstructorReturn(this, (ListSelectComponent.__proto__ || Object.getPrototypeOf(ListSelectComponent)).call(this, $.extend(true, {
				model: {
					// list of elements to select from
					elements: [],

					// maximum elements per row on largest viewport
					elementsPerRow: 4
				}
			}, params)));

			_this.allowMultiple = $.orDefault(params.model.allowMultiple, false, 'bool');

			// value of currently selected element(s)
			_this.viewModel.selected = ko.observable('');

			//^^^
			_this.viewModel.description = ko.observable('');

			//^^^
			_this.viewModel.internalValue = ko.computed({
				read: function read() {
					return _this.viewModel.selected();
				},
				write: function write(value) {
					_this.viewModel.selected('' + value);
				}
			}).extend({ notify: 'always' });
			_this._addDisposable(_this.viewModel.internalValue);

			// split elements into semantic rows according to elementsPerRow, to ease styling and responsive behaviour
			_this.viewModel.elementRows = ko.computed(function () {
				// hook to tag changes to update on possible visibility changes
				_this.app.viewModel.tagsChanged();

				var rows = [],
				    row = [];

				_.each(_this.viewModel.elements(), function (el) {
					if (row.length > 0 && row.length % _this.viewModel.elementsPerRow() === 0) {
						if (row) {
							rows.push(row);
						}
						row = [];
					}

					if (!el.neededTags || _this.app.tagsArePresent(el.neededTags())) {
						row.push(el);
					}
				});

				if (row) {
					rows.push(row);
				}

				return rows;
			});
			_this._addDisposable(_this.viewModel.elementRows);

			// automatically remove obsolete values from component value if elements are not being rendered any more
			var valueUpdateTimer = $.schedule(1, $.noop);
			_this._addDisposable(_this.app.viewModel.tagsChanged.subscribe(function (newValue) {
				// todo: doing this with a timer is dirty, replace with clean knockout way when found
				valueUpdateTimer = $.reschedule(valueUpdateTimer, 50, function () {
					var obsoleteElementValues = [],
					    obsoleteDescriptions = [];

					_.each(_this.viewModel.elements(), function (el) {
						if ($.isFunction(el.neededTags) && !_this.app.tagsArePresent(el.neededTags())) {
							obsoleteElementValues.push(el.value());
							obsoleteDescriptions.push(el.title());
						}
					});

					_.each(obsoleteElementValues, function (val, index) {
						_this.clearSub(val, obsoleteDescriptions[index]);
					});
				});
			}));
			return _this;
		}

		_createClass(ListSelectComponent, [{
			key: 'dispose',
			value: function dispose() {
				_get(ListSelectComponent.prototype.__proto__ || Object.getPrototypeOf(ListSelectComponent.prototype), 'dispose', this).call(this);
			}

			//###( FUNCTIONALITY )###

			//^^^

		}, {
			key: 'initAllTags',
			value: function initAllTags() {
				var allTags = new Set();

				_.each(this.viewModel.elements(), function (element) {
					allTags = (0, _set.union)(allTags, new Set(element.tags()));
				});

				this.viewModel.allTags = ko.observableArray([].concat(_toConsumableArray(allTags)));
			}

			//^^^

		}, {
			key: 'buildActiveTags',
			value: function buildActiveTags(value) {
				var _this2 = this;

				value = $.orDefault(value, this.viewModel.internalValue(), 'string');

				var values = _.map(_.compact(value.split(';')), function (val) {
					return _.trim(val);
				}),
				    activeTags = new Set();

				_.each(values, function (val) {
					_.each(_this2.viewModel.elements(), function (element) {
						if (val === element.value()) {
							activeTags = (0, _set.union)(activeTags, new Set(element.tags()));
						}
					});
				});

				this.viewModel.activeTags = ko.observableArray([].concat(_toConsumableArray(activeTags)));
			}

			/**
    * Select a certain value / set selected value of component, single select overwrites values, multiple appends.
    **/

		}, {
			key: 'select',
			value: function select(value, description) {
				description = _.trim(description);
				value = _.trim(value);

				// check if value actually belongs to an element
				var element = _.find(this.viewModel.elements(), function (el) {
					return el.value() === value && el.title() === description;
				});
				$.assert($.isSet(element), 'ListSelectComponent.select | can not be selected, value or description do not belong to any element');

				if (this.allowMultiple) {
					var descriptionList = _.compact(this.viewModel.description().split(';'));
					_.remove(descriptionList, function (desc) {
						return desc === description;
					});
					descriptionList.push(description);
					this.viewModel.description(descriptionList.join(';'));

					var selectedList = _.compact(this.viewModel.selected().split(';'));
					_.remove(selectedList, function (val) {
						return val === value;
					});
					selectedList.push(value);
					this.viewModel.selected(selectedList.join(';'));
				} else {
					this.viewModel.description(description);
					this.viewModel.selected(value);
				}
			}

			/**
    * Remove a certain value from the currently selected ones, if single select, just empties values.
    **/

		}, {
			key: 'deselect',
			value: function deselect(value) {
				if (this.allowMultiple) {
					value = _.trim(value);

					var selectedList = _.compact(this.viewModel.selected().split(';')),
					    selectedIndex = _.indexOf(selectedList, value);

					if (selectedIndex >= 0) {
						var descriptionList = _.compact(this.viewModel.description().split(';'));
						_.remove(descriptionList, function (desc) {
							return desc === descriptionList[selectedIndex];
						});
						this.viewModel.description(descriptionList.join(';'));

						_.remove(selectedList, function (val) {
							return val === value;
						});
						this.viewModel.selected(selectedList.join(';'));
					}
				} else {
					this.viewModel.description('');
					this.viewModel.selected('');
				}
			}

			/**
    * Template helper to automatically generate layout classes for an element row, based on the component's config.
    **/

		}, {
			key: 'generateRowClasses',
			value: function generateRowClasses(row) {
				var classes = '';

				// elementspaces defines how many "slots" for elements should be there
				classes += 'elementspaces-' + this.viewModel.elementsPerRow();

				// elementcount is the realy number of present elements in the row
				classes += ' elementcount-' + row.length;

				// freespace is the percentage of unused space in the row, based on spaces and rendered elements
				classes += ' freespace-' + 100 / this.viewModel.elementsPerRow() * (this.viewModel.elementsPerRow() - row.length);

				return classes;
			}
		}]);

		return ListSelectComponent;
	}(_component.Component);
});
