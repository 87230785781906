define('app/schemas/requestanswerschemas',['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var PLATEWIDTHS_SCHEMA = exports.PLATEWIDTHS_SCHEMA = {
		type: 'object',
		properties: {
			'effective': {
				type: 'array',
				minLength: 1,
				items: {
					type: 'integer',
					gte: 1,
					lte: 10000
				}
			},
			'total': {
				type: 'array',
				minLength: 1,
				items: {
					type: 'integer',
					gte: 1,
					lte: 10000
				}
			},
			'structured': {
				type: 'object',
				properties: {
					'*': {
						type: 'integer',
						gte: 1,
						lte: 10000
					}
				}
			},
			'ids': {
				type: 'object',
				properties: {
					'*': {
						type: 'integer'
					}
				}
			},
			'meta': {
				type: 'object',
				properties: {
					'spaceBetween': {
						type: 'integer',
						gte: 0,
						lte: 1000
					},
					'spaceLeftRight': {
						type: 'integer',
						gte: 0,
						lte: 1000
					},
					'minWidth': {
						type: 'integer',
						gte: 0,
						lte: 1000
					}
				}
			}
		}
	};

	var PLATEWIDTHS_ANSWER_SCHEMA = exports.PLATEWIDTHS_ANSWER_SCHEMA = {
		type: 'object',
		properties: {
			'widths': PLATEWIDTHS_SCHEMA
		}
	};

	var PARTLIST_ANSWER_SCHEMA = exports.PARTLIST_ANSWER_SCHEMA = {
		type: 'object',
		properties: {
			'partlist': {
				type: 'object',
				properties: {
					'baseUrl': {
						type: 'string',
						pattern: /\/[a-zA-Z0-9\-\/]+\/?/
					},
					'hash': {
						type: 'string',
						pattern: /[a-zA-Z0-9]+/
					},
					'url': {
						type: 'string',
						pattern: /\/[a-zA-Z0-9\-\/]+\/?/
					}
				}
			}
		}
	};

	var CUSTOMERINQUIRYTOKEN_ANSWER_SCHEMA = exports.CUSTOMERINQUIRYTOKEN_ANSWER_SCHEMA = {
		type: 'object',
		properties: {
			'customerInquiry': {
				type: 'object',
				properties: {
					'token': {
						type: 'string',
						pattern: /[a-zA-Z0-9\-]{14}/
					},
					'date': {
						type: 'string'
						//pattern : /[0-9]{1,4}(\.|\-|\/)[0-9]{1,4}(\.|\-|\/)[0-9]{1,4}/
					}
				}
			}
		}
	};
});
