define('util/set',["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.union = union;
  exports.intersection = intersection;
  exports.difference = difference;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  /**
   * Helper functions to work with ES6's sets
   **/

  /**
   * Return a Set containing all elements of both parameter Sets
   **/
  function union(a, b) {
    return new Set([].concat(_toConsumableArray(a), _toConsumableArray(b)));
  }

  /**
   * Return a Set containing all elements present in both parameter Sets
   **/
  function intersection(a, b) {
    return new Set([].concat(_toConsumableArray(a)).filter(function (x) {
      return b.has(x);
    }));
  }

  /**
   * Return a Set containing all elements only present in one of the parameter Sets
   **/
  function difference(a, b) {
    return new Set([].concat(_toConsumableArray(a)).filter(function (x) {
      return !b.has(x);
    }));
  }
});
