define('app/components/dimensions',['exports', 'lib/shim', 'util/set', 'util/value', 'util/tracking', 'app/abstract/component', 'app/abstract/subcomponent', 'app/subcomponents/selectelement', 'app/schemas/requestanswerschemas'], function (exports, _shim, _set, _value, _tracking, _component, _subcomponent, _selectelement, _requestanswerschemas) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.DimensionsComponent = exports.PLATECONFIG_REX = undefined;

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
				arr2[i] = arr[i];
			}

			return arr2;
		} else {
			return Array.from(arr);
		}
	}

	function _classCallCheck(instance, Constructor) {
		if (!(instance instanceof Constructor)) {
			throw new TypeError("Cannot call a class as a function");
		}
	}

	function _possibleConstructorReturn(self, call) {
		if (!self) {
			throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
		}

		return call && (typeof call === "object" || typeof call === "function") ? call : self;
	}

	var _get = function get(object, property, receiver) {
		if (object === null) object = Function.prototype;
		var desc = Object.getOwnPropertyDescriptor(object, property);

		if (desc === undefined) {
			var parent = Object.getPrototypeOf(object);

			if (parent === null) {
				return undefined;
			} else {
				return get(parent, property, receiver);
			}
		} else if ("value" in desc) {
			return desc.value;
		} else {
			var getter = desc.get;

			if (getter === undefined) {
				return undefined;
			}

			return getter.call(receiver);
		}
	};

	var _createClass = function () {
		function defineProperties(target, props) {
			for (var i = 0; i < props.length; i++) {
				var descriptor = props[i];
				descriptor.enumerable = descriptor.enumerable || false;
				descriptor.configurable = true;
				if ("value" in descriptor) descriptor.writable = true;
				Object.defineProperty(target, descriptor.key, descriptor);
			}
		}

		return function (Constructor, protoProps, staticProps) {
			if (protoProps) defineProperties(Constructor.prototype, protoProps);
			if (staticProps) defineProperties(Constructor, staticProps);
			return Constructor;
		};
	}();

	function _inherits(subClass, superClass) {
		if (typeof superClass !== "function" && superClass !== null) {
			throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
		}

		subClass.prototype = Object.create(superClass && superClass.prototype, {
			constructor: {
				value: subClass,
				enumerable: false,
				writable: true,
				configurable: true
			}
		});
		if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
	}

	//###[ CONSTANTS ]######################################################################################################

	var TEMPLATE_SELECTOR = '#dimensions-component-template';

	var WIDTHXLENGTH_REX = /^([1-9][0-9]*)x([1-9][0-9]*)$/;
	var PLATECONFIGITEM_REX = new RegExp('^([1-9][0-9]*)\\*' + $.strReplace(['^', '$'], '', WIDTHXLENGTH_REX.source) + '\\(([1-9][0-9]*)\\)$');
	// gets completed in buildElements by using the option values as possible prefixes
	var PLATECONFIG_REX = exports.PLATECONFIG_REX = new RegExp('^(PREFIXES)\\|' + $.strReplace(['^', '$'], '', WIDTHXLENGTH_REX.source) + '\\|' + $.strReplace(['^', '$'], '', PLATECONFIGITEM_REX.source) + '(\\;' + $.strReplace(['^', '$'], '', PLATECONFIGITEM_REX.source) + ')*$');

	//###[ SCHEMA ]#########################################################################################################

	var INTRO_SCHEMA = {
		type: 'object',
		properties: {
			'image': {
				// is validated by widget/image
				type: 'object'
			},
			'info': {
				// is validated by widget/info
				optional: true,
				type: 'object'
			}
		}
	};

	var DIMENSIONS_SCHEMA = {
		type: 'object',
		properties: {
			'width': {
				type: 'object',
				properties: {
					'label': {
						type: 'string',
						minLength: 1
					},
					'info': {
						// is validated by widget/info
						optional: true,
						type: 'object'
					}
				}
			},
			'length': {
				type: 'object',
				properties: {
					'label': {
						type: 'string',
						minLength: 1
					},
					'info': {
						// is validated by widget/info
						optional: true,
						type: 'object'
					}
				}
			}
		}
	};

	var HELP_SCHEMA = {
		type: 'object',
		properties: {
			'explanation': {
				type: 'object',
				properties: {
					'headline': {
						type: 'string',
						minLength: 1
					},
					'text': {
						optional: true,
						type: 'string'
					}
				}
			},
			'depth': {
				type: 'object',
				properties: {
					'label': {
						type: 'string',
						minLength: 1
					},
					'info': {
						// is validated by widget/info
						optional: true,
						type: 'object'
					}
				}
			},
			'height': {
				type: 'object',
				properties: {
					'label': {
						type: 'string',
						minLength: 1
					},
					'info': {
						// is validated by widget/info
						optional: true,
						type: 'object'
					}
				}
			},
			'degree': {
				type: 'object',
				properties: {
					'label': {
						type: 'string',
						minLength: 1
					},
					'info': {
						// is validated by widget/info
						optional: true,
						type: 'object'
					}
				}
			}
		}
	};

	var OPTIONS_SCHEMA = {
		type: 'object',
		properties: {
			'methodSelect': {
				type: 'object',
				properties: {
					'explanation': {
						type: 'object',
						properties: {
							'headline': {
								type: 'string',
								minLength: 1
							},
							'text': {
								optional: true,
								type: 'string'
							}
						}
					},
					'asymmetric': {
						type: 'object',
						properties: {
							'element': {
								// is validated by subcomponent/selectelement
								type: 'object'
							}
						}
					},
					'symmetric': {
						type: 'object',
						properties: {
							'element': {
								// is validated by subcomponent/selectelement
								type: 'object'
							}
						}
					}
				}
			},
			'automatic': {
				type: 'object',
				properties: {
					'explanation': {
						type: 'object',
						properties: {
							'headline': {
								type: 'string',
								minLength: 1
							},
							'explanation': {
								optional: true,
								type: 'string'
							}
						}
					},
					'element': $.extend({}, _subcomponent.SCHEMA, _selectelement.SCHEMA)
				}
			}
		}
	};

	var SCHEMA = {
		type: 'object',
		properties: {
			model: {
				type: 'object',
				properties: {
					intro: INTRO_SCHEMA,
					dimensions: DIMENSIONS_SCHEMA,
					help: HELP_SCHEMA,
					options: OPTIONS_SCHEMA
				}
			}
		}
	};

	//###[ DIMENSIONSCOMPONENT ]#################################################################################################

	/**
  * This component receives width and length values of a roof and calculates the needed plates for that context.
  **/

	var DimensionsComponent = exports.DimensionsComponent = function (_Component) {
		_inherits(DimensionsComponent, _Component);

		_createClass(DimensionsComponent, null, [{
			key: 'register',


			//###( STATIC )###

			value: function register() {
				_component.Component.register('DimensionsComponent', DimensionsComponent, TEMPLATE_SELECTOR);
			}

			//###( CLASS )###

		}]);

		function DimensionsComponent(params) {
			_classCallCheck(this, DimensionsComponent);

			// strip possible observables
			params = ko.viewmodel.toModel(params);
			var paramsInspection = _shim.SchemaInspector.validate(SCHEMA, params);
			$.assert(paramsInspection.valid, 'DimensionsComponent.constructor | params does not fit schema (' + paramsInspection.format() + ')');

			// the calculated plate config in the format PLATECONFIG_REX
			var _this = _possibleConstructorReturn(this, (DimensionsComponent.__proto__ || Object.getPrototypeOf(DimensionsComponent)).call(this, $.extend(true, {
				model: {
					// contains all data for the introducing image
					intro: undefined,

					// contains all data for the manual dimension entry
					dimensions: undefined,

					// contains all data for the helper widget for easier setting of the length
					help: undefined,

					// contains all data for option selection
					options: undefined
				}
			}, params)));

			_this.viewModel.plateConfig = ko.observable('');

			// based on the config made until the dimensions may be set, individual plate widths have to be requested at the parts api
			_this.viewModel.plateWidths = {};

			// mirror input of roof width in mm
			_this.viewModel.roofWidth = ko.observable('');

			// mirror input of roof length in mm
			_this.viewModel.roofLength = ko.observable('');

			// mirror the help input for roof depth
			_this.viewModel.helpDepth = ko.observable('');

			// mirror the help input for roof height
			_this.viewModel.helpHeight = ko.observable('');

			// mirror the help input for roof degree
			_this.viewModel.helpDegree = ko.observable('');

			// holder for non automatic option elements, contents must be compatible to SelectElement
			_this.viewModel.elements = ko.observableArray([]);

			// keeps the selected option, is needed by subcomponent SelectElement under this name
			_this.viewModel.selected = ko.observable('');

			// automatically compile user entry to observable interim value
			_this.viewModel.widthXlength = ko.computed(function () {
				_this.viewModel.roofWidth();
				_this.viewModel.roofLength();

				if (_this.viewModel.roofWidth() !== '' && _this.viewModel.roofLength() !== '') {
					return _this.viewModel.roofWidth() + 'x' + _this.viewModel.roofLength();
				} else {
					return '';
				}
			});
			_this._addDisposable(_this.viewModel.widthXlength);

			// the current button-press-approved live values for width and length
			_this.viewModel.approvedWidthXlength = ko.observable(_this.viewModel.widthXlength());

			// keeps plate list for automatic calculation without selecting a method via options
			_this.viewModel.automaticList = ko.viewmodel.fromModel({
				type: 'result',
				items: [],
				rawItems: []
			});

			//^^^
			_this.viewModel.description = ko.observable('');

			//^^^
			_this.viewModel.internalValue = ko.computed({
				read: function read() {
					return _this.viewModel.plateConfig();
				},
				write: function write(value) {
					if (value !== '') {
						var matches = PLATECONFIG_REX.test('' + value);
						$.assert(matches, 'DimensionsComponent.viewModel.internalValue.write | could not set internal value, wrong format');

						var typeDimsConfigs = value.split('|'),
						    type = typeDimsConfigs[0],
						    dims = typeDimsConfigs[1].split('x'),
						    configs = typeDimsConfigs[2].split(';');

						_this.viewModel.roofWidth(parseInt(dims[0], 10));
						_this.viewModel.roofLength(parseInt(dims[1], 10));
						_this.viewModel.approvedWidthXlength(_this.viewModel.widthXlength());
						_this.viewModel.selected(type);
						_this.viewModel.plateConfig(value);
					} else {
						_this.viewModel.roofWidth('');
						_this.viewModel.roofLength('');
						_this.viewModel.approvedWidthXlength(_this.viewModel.widthXlength());
						_this.viewModel.selected('');
						_this.viewModel.plateConfig('');
					}
				}
			}).extend({ notify: 'always' });
			_this._addDisposable(_this.viewModel.internalValue);

			// provide information if the options are in selection mode or are automatic
			_this.viewModel.optionsAreAutomatic = _this.app.cTagsArePresent(_this.viewModel.options.automatic.element.neededTags ? _this.viewModel.options.automatic.element.neededTags() : []);
			_this._addDisposable(_this.viewModel.optionsAreAutomatic);

			_this._setupValidators();

			// load product info based on the config so far, for example plate widths based on selected type and material
			_this.viewModel.configInfoLoadedStatus = ko.observable('init');
			_this._manageHelp();
			_this._buildElements();
			return _this;
		}

		_createClass(DimensionsComponent, [{
			key: 'dispose',
			value: function dispose() {
				_get(DimensionsComponent.prototype.__proto__ || Object.getPrototypeOf(DimensionsComponent.prototype), 'dispose', this).call(this);
			}

			//###( FUNCTIONALITY )###

			//^^^

		}, {
			key: 'initAllTags',
			value: function initAllTags() {
				var allTags = new Set();

				_.each([this.viewModel.options.methodSelect.asymmetric.element, this.viewModel.options.methodSelect.symmetric.element, this.viewModel.options.automatic.element], function (element) {
					allTags = (0, _set.union)(allTags, new Set(element.tags()));
				});

				this.viewModel.allTags = ko.observableArray([].concat(_toConsumableArray(allTags)));
			}

			//^^^

		}, {
			key: 'buildActiveTags',
			value: function buildActiveTags(value) {
				value = $.orDefault(value, this.viewModel.internalValue(), 'string');

				var type = value.split('|')[0],
				    activeTags = new Set();

				_.each([this.viewModel.options.methodSelect.asymmetric.element, this.viewModel.options.methodSelect.symmetric.element, this.viewModel.options.automatic.element], function (element) {
					if (type === element.value()) {
						activeTags = (0, _set.union)(activeTags, new Set(element.tags()));
					}
				});

				this.viewModel.activeTags = ko.observableArray([].concat(_toConsumableArray(activeTags)));
			}

			/**
    * Select a certain option. Only applies if selection is not automatic.
    **/

		}, {
			key: 'selectOption',
			value: function selectOption(value, description) {
				if (!this.viewModel.optionsAreAutomatic()) {
					description = _.trim(description);
					value = _.trim(value);

					var orgValue = value.split('|')[0];

					// check if value actually belongs to an element
					var element = _.find(this.viewModel.elements(), function (el) {
						return el.value() === orgValue && _.startsWith(description, el.title());
					});
					$.assert($.isSet(element), 'DimensionsComponent.selectOption | can not be selected, value or description do not belong to any element');

					var roofDimsSummary = this.viewModel.approvedWidthXlength().split('x');
					roofDimsSummary = $.strFormat(this.app.getText('dimensions.plateDimsSummary'), {
						width: roofDimsSummary[0],
						length: roofDimsSummary[1]
					});

					this.viewModel.selected(orgValue);
					this.viewModel.description(roofDimsSummary + '; ' + description);
					this.viewModel.internalValue(value);
				}
			}

			/**
    * Deselects the currently selected option. Only applies if selection is not automatic.
    **/

		}, {
			key: 'deselectOption',
			value: function deselectOption() {
				if (!this.viewModel.optionsAreAutomatic()) {
					this.viewModel.selected('');
					this.viewModel.plateConfig('');
					this.viewModel.description('');
				}
			}

			/**
    * Update the approved value of widthXLength to the currently entered. Normally happens manually, by
    * pressing the corresponding button next to the dimension inputs.
    **/

		}, {
			key: 'updateWidthXLength',
			value: function updateWidthXLength() {
				var _this2 = this;

				this.viewModel.configInfoLoadedStatus('pending');

				if (this.viewModel.widthXLengthIsValid()) {
					$.getJSON('/parts/profile-widths/', { tags: this.app.getTags(), length: this.viewModel.roofLength() }).done(function (json) {
						var answerInspection = _shim.SchemaInspector.validate(_requestanswerschemas.PLATEWIDTHS_ANSWER_SCHEMA, json);

						if (answerInspection.valid) {
							json.widths.effective = _.sortBy(json.widths.effective);
							json.widths.total = _.sortBy(json.widths.total);
							_this2.viewModel.plateWidths = ko.viewmodel.fromModel(json.widths);
							// beware, this triggers rendering of options, before this happens, plateWidths have to be set and valid
							_this2.viewModel.approvedWidthXlength(_this2.viewModel.widthXlength());

							// if options are automatic, we build an element on the fly, based on the entered dims
							// and directly set its value
							if (_this2.viewModel.optionsAreAutomatic()) {
								_this2._buildAutomaticPartList();
								_this2.viewModel.selected('');
								_this2.viewModel.description(_.slice(_this2.viewModel.automaticList.items(), 1).join(';'));

								var compiledValue = '';
								_.each(_this2.viewModel.automaticList.rawItems(), function (item, index) {
									if (index > 0) {
										compiledValue += ';';
									}
									compiledValue += $.strFormat('{count}*{width}x{length}({id})', item);
								});

								_this2.viewModel.internalValue(_this2.viewModel.options.automatic.element.value() + '|' + _this2.viewModel.approvedWidthXlength() + '|' + compiledValue);

								// has to be handled manually since we have no element to click here,
								// defer to wait for element rendering
								_.defer(function () {
									var $element = $('[data-id="' + _this2.viewModel.id() + '"] .automatic-option').first();
									if ($.exists($element)) {
										_tracking.GoogleAnalytics.DataLayer.trackElementSelection($element, 'optionswahl', _this2.step.viewModel.number(), 'Automatischer Zuschnitt');
									}
								});
								// otherwise just reset option selection and force user to select an option
							} else {
								_this2.deselectOption();
							}

							_this2.viewModel.configInfoLoadedStatus('success');

							// scroll to option selection if user interaction is necessary
							$.schedule(100, function () {
								var innerHeight = window.innerHeight || $(window).height();
								$('[data-id="' + _this2.viewModel.id() + '"] .options-holder').first().scrollTo($.noop, 500, Math.round(innerHeight / 3), true);
							});
						} else {
							$.warn('DimensionsComponent.updateWidthXLength | width answer does not fit schema (' + answerInspection.format() + ')');

							_this2.app.showError(_this2.app.getText('errors.defaultTitle'), $.elem('p').text(_this2.app.getText('errors.missingDataErrorMessage')), true);

							_this2.viewModel.configInfoLoadedStatus('error');
						}
					}).fail(function () {
						_this2.app.showError(_this2.app.getText('errors.defaultTitle'), $.elem('p').text(_this2.app.getText('errors.serverCommunicationErrorMessage')), true);

						_this2.viewModel.configInfoLoadedStatus('error');
					});
				}
			}

			/**
    * Use the helper values to calculate and set a new roof length in the dimensions input.
    **/

		}, {
			key: 'updateRoofLengthByHelpDepthXheight',
			value: function updateRoofLengthByHelpDepthXheight() {
				var _this3 = this;

				if (this.viewModel.helpValuesAreUsable()) {
					var newRoofLength = Math.ceil(Math.sqrt(Math.pow(this.viewModel.helpDepth(), 2) + Math.pow(this.viewModel.helpHeight(), 2)));

					this.viewModel.roofLength(newRoofLength);

					$.schedule(100, function () {
						//let innerHeight = window.innerHeight || $(window).height();
						$('[data-id="' + _this3.viewModel.id() + '"] .dimensions-holder').first().scrollTo($.noop, 500, 0, true);
					});
				}
			}

			//###( PROTECTED )###

			/**
    * Setup for all validation observables used by the several inputs to set invalid states based on their values.
    **/

		}, {
			key: '_setupValidators',
			value: function _setupValidators() {
				var _this4 = this;

				this.viewModel.roofWidthIsValid = ko.computed(function () {
					_this4.viewModel.roofWidth();
					return _this4.viewModel.roofWidth() === '' && _this4.viewModel.roofLength() === '' || (0, _value.isPositiveIntegerString)(_this4.viewModel.roofWidth()) && _shim.SchemaInspector.validate({
						type: 'integer',
						gte: 1000,
						lte: 10000
					}, parseInt(_this4.viewModel.roofWidth(), 10)).valid;
				});
				this._addDisposable(this.viewModel.roofWidthIsValid);

				this.viewModel.roofLengthIsValid = ko.computed(function () {
					_this4.viewModel.roofLength();
					return _this4.viewModel.roofWidth() === '' && _this4.viewModel.roofLength() === '' || (0, _value.isPositiveIntegerString)(_this4.viewModel.roofLength()) && _shim.SchemaInspector.validate({
						type: 'integer',
						gte: 2000,
						lte: 7000
					}, parseInt(_this4.viewModel.roofLength(), 10)).valid;
				});
				this._addDisposable(this.viewModel.roofLengthIsValid);

				this.viewModel.widthXLengthIsValid = ko.computed(function () {
					return _this4.viewModel.roofWidthIsValid() && _this4.viewModel.roofLengthIsValid();
				});
				this._addDisposable(this.viewModel.widthXLengthIsValid);

				this.viewModel.helpDepthIsValid = ko.computed(function () {
					_this4.viewModel.helpDepth();
					return _this4.viewModel.helpDepth() === '' && _this4.viewModel.helpHeight() === '' && _this4.viewModel.helpDegree() === '' || (0, _value.isPositiveIntegerString)(_this4.viewModel.helpDepth()) && _shim.SchemaInspector.validate({
						type: 'integer',
						gte: 1,
						lte: 10000
					}, parseInt(_this4.viewModel.helpDepth(), 10)).valid;
				});
				this._addDisposable(this.viewModel.helpDepthIsValid);

				this.viewModel.helpHeightIsValid = ko.computed(function () {
					_this4.viewModel.helpHeight();
					return _this4.viewModel.helpDepth() === '' && _this4.viewModel.helpHeight() === '' && _this4.viewModel.helpDegree() === '' || (0, _value.isPositiveIntegerString)(_this4.viewModel.helpHeight()) && _shim.SchemaInspector.validate({
						type: 'integer',
						gte: 0,
						lte: 10000
					}, parseInt(_this4.viewModel.helpHeight(), 10)).valid;
				});
				this._addDisposable(this.viewModel.helpHeightIsValid);

				this.viewModel.helpDegreeIsValid = ko.computed(function () {
					_this4.viewModel.helpDegree();
					return _this4.viewModel.helpDepth() === '' && _this4.viewModel.helpHeight() === '' && _this4.viewModel.helpDegree() === '' || (0, _value.isPositiveIntegerString)(_this4.viewModel.helpDegree()) && _shim.SchemaInspector.validate({
						type: 'integer',
						gte: 5,
						lte: 30
					}, parseInt(_this4.viewModel.helpDegree(), 10)).valid;
				});
				this._addDisposable(this.viewModel.helpDegreeIsValid);

				this.viewModel.helpValuesAreUsable = ko.computed(function () {
					return _this4.viewModel.helpDepth() !== '' && _this4.viewModel.helpHeight() !== '' && _this4.viewModel.helpDegree() !== '' && _this4.viewModel.helpDepthIsValid() && _this4.viewModel.helpHeightIsValid() && _this4.viewModel.helpDegreeIsValid();
				});
				this._addDisposable(this.viewModel.helpValuesAreUsable);
			}

			/**
    * Construct elements usable as initial values for SelectElements,
    * based on the options available, if options are not automatic.
    **/

		}, {
			key: '_buildElements',
			value: function _buildElements() {
				this.viewModel.elements.removeAll();

				this.viewModel.elements.push({
					value: this.viewModel.options.methodSelect.asymmetric.element.value,
					title: this.viewModel.options.methodSelect.asymmetric.element.title
				});

				this.viewModel.elements.push({
					value: this.viewModel.options.methodSelect.symmetric.element.value,
					title: this.viewModel.options.methodSelect.symmetric.element.title
				});

				// add format prefixes based on possible options to regex
				var prefixes = this.viewModel.options.methodSelect.asymmetric.element.value() + '|' + this.viewModel.options.methodSelect.symmetric.element.value() + '|' + this.viewModel.options.automatic.element.value();
				exports.PLATECONFIG_REX = PLATECONFIG_REX = new RegExp($.strReplace('PREFIXES', prefixes, PLATECONFIG_REX.source));
			}

			/**
    * Setup behaviour of the helper widgets meant to support the user in getting the right roofLength
    * with roofs being installed with a downward slope. Basically just calculates the longer side of
    * a triangle with a 90° angle between the two shorter sides based on the angle between the shorter and
    * the longer side or the lengths of both shorter sides.
    **/

		}, {
			key: '_manageHelp',
			value: function _manageHelp() {
				var _this5 = this;

				// prevents update loops
				this.helpAutoUpdateInProgress = false;

				this._addDisposable(this.viewModel.helpDepth.subscribe(function (newValue) {
					// if depth is changed, update all values based on the first available, starting with height
					if (_this5.viewModel.helpHeight() !== '' && _this5.viewModel.helpHeightIsValid()) {
						// force update by resetting value
						var height = _this5.viewModel.helpHeight();
						_this5.viewModel.helpHeight(1);
						_.defer(function () {
							_this5.viewModel.helpHeight(height);
						});
					} else if (_this5.viewModel.helpDegree() !== '' && _this5.viewModel.helpDegreeIsValid()) {
						// force update by resetting values
						var degree = _this5.viewModel.helpDegree();
						_this5.viewModel.helpDegree(1);
						_.defer(function () {
							_this5.viewModel.helpDegree(degree);
						});
					}
				}));

				this._addDisposable(this.viewModel.helpHeight.subscribe(function (newValue) {
					if (!_this5.helpAutoUpdateInProgress && _this5.viewModel.helpDepth() !== '' && _this5.viewModel.helpDepthIsValid() && _this5.viewModel.helpHeightIsValid()) {
						// if update possible use basic triangle arithmetics to calculate degree by both set shorter sides
						_this5.helpAutoUpdateInProgress = true;
						var newDegree = Math.round(Math.atan(parseInt(newValue, 10) / parseInt(_this5.viewModel.helpDepth(), 10)) * (180 / Math.PI));

						// force update by resetting value and prevent collision by deferring unlock
						newDegree = $.isNaN(newDegree) ? '' : '' + newDegree;
						_this5.viewModel.helpDegree(newDegree);
						_.defer(function () {
							_this5.helpAutoUpdateInProgress = false;
						});
					}
				}));

				this._addDisposable(this.viewModel.helpDegree.subscribe(function (newValue) {
					if (!_this5.helpAutoUpdateInProgress && _this5.viewModel.helpDepth() !== '' && _this5.viewModel.helpDepthIsValid() && _this5.viewModel.helpDegreeIsValid()) {
						// if update is possible use basic triangle arithmetics to calculate shorter side based on entered degree
						_this5.helpAutoUpdateInProgress = true;
						var newHeight = Math.round(Math.tan(parseInt(newValue, 10) * (Math.PI / 180)) * parseInt(_this5.viewModel.helpDepth(), 10));

						// force update by resetting value and prevent collision by deferring unlock
						newHeight = $.isNaN(newHeight) ? '' : '' + newHeight;
						_this5.viewModel.helpHeight(newHeight);
						_.defer(function () {
							_this5.helpAutoUpdateInProgress = false;
						});
					}
				}));
			}

			/**
    * Builds the list of parts to display in the automatic option, compatible to the list display of this
    * component's main template. Get updated if method is automatic and dimensions change.
    **/

		}, {
			key: '_buildAutomaticPartList',
			value: function _buildAutomaticPartList() {
				var _this6 = this;

				this.viewModel.automaticList.rawItems.removeAll();
				this.viewModel.automaticList.items.removeAll();

				var widthXlength = this.viewModel.approvedWidthXlength().split('x'),
				    width = parseInt(widthXlength[0], 10) - this.viewModel.plateWidths.meta.spaceLeftRight() * 2,
				    effectivePlateWidths = _.reverse(_.sortBy(this.viewModel.plateWidths.effective())),
				    restWidth = width,
				    length = parseInt(widthXlength[1], 10),
				    res = {};

				// set headline as first element of list
				this.viewModel.automaticList.items.push(this.viewModel.options.automatic.element.title());

				while (restWidth > 0) {
					var found = false;

					// try to fit a regular plate in the remaining space with enough space to add another minimal plate
					_.each(effectivePlateWidths, function (plateWidth) {
						if (restWidth >= plateWidth) {
							if (!$.isSet(res['' + plateWidth])) {
								res['' + plateWidth] = {
									count: 0,
									width: _this6.viewModel.plateWidths.structured['' + plateWidth],
									length: length,
									id: _this6.viewModel.plateWidths.ids['' + plateWidth]
								};
							}

							res['' + plateWidth].count++;
							restWidth -= plateWidth;

							found = true;
							return false;
						}
					});

					if (!found) {
						var plateWidth = _.last(effectivePlateWidths);

						if (!$.isSet(res['' + plateWidth])) {
							res['' + plateWidth] = {
								count: 0,
								width: this.viewModel.plateWidths.structured['' + plateWidth],
								length: length,
								id: this.viewModel.plateWidths.ids['' + plateWidth]
							};
						}

						res['' + plateWidth].count++;
						restWidth -= plateWidth;
					}
				}

				_.each(effectivePlateWidths, function (plateWidth) {
					if ($.isSet(res['' + plateWidth])) {
						var item = {
							count: res['' + plateWidth].count,
							width: res['' + plateWidth].width,
							length: res['' + plateWidth].length,
							id: res['' + plateWidth].id
						};

						_this6.viewModel.automaticList.rawItems.push($.extend({}, item));
						_this6.viewModel.automaticList.items.push($.strFormat(_this6.app.getText('dimensions.resultListItem'), item));

						delete res['' + plateWidth];
					}
				});
			}
		}]);

		return DimensionsComponent;
	}(_component.Component);
});
