define('app/parts/stepnavigation',['exports', 'lib/shim', 'app/abstract/part'], function (exports, _shim, _part) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.StepNavigationPart = undefined;

	function _classCallCheck(instance, Constructor) {
		if (!(instance instanceof Constructor)) {
			throw new TypeError("Cannot call a class as a function");
		}
	}

	function _possibleConstructorReturn(self, call) {
		if (!self) {
			throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
		}

		return call && (typeof call === "object" || typeof call === "function") ? call : self;
	}

	var _get = function get(object, property, receiver) {
		if (object === null) object = Function.prototype;
		var desc = Object.getOwnPropertyDescriptor(object, property);

		if (desc === undefined) {
			var parent = Object.getPrototypeOf(object);

			if (parent === null) {
				return undefined;
			} else {
				return get(parent, property, receiver);
			}
		} else if ("value" in desc) {
			return desc.value;
		} else {
			var getter = desc.get;

			if (getter === undefined) {
				return undefined;
			}

			return getter.call(receiver);
		}
	};

	var _createClass = function () {
		function defineProperties(target, props) {
			for (var i = 0; i < props.length; i++) {
				var descriptor = props[i];
				descriptor.enumerable = descriptor.enumerable || false;
				descriptor.configurable = true;
				if ("value" in descriptor) descriptor.writable = true;
				Object.defineProperty(target, descriptor.key, descriptor);
			}
		}

		return function (Constructor, protoProps, staticProps) {
			if (protoProps) defineProperties(Constructor.prototype, protoProps);
			if (staticProps) defineProperties(Constructor, staticProps);
			return Constructor;
		};
	}();

	function _inherits(subClass, superClass) {
		if (typeof superClass !== "function" && superClass !== null) {
			throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
		}

		subClass.prototype = Object.create(superClass && superClass.prototype, {
			constructor: {
				value: subClass,
				enumerable: false,
				writable: true,
				configurable: true
			}
		});
		if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
	}

	//###[ CONSTANTS ]######################################################################################################

	var TEMPLATE_SELECTOR = '#stepnavigation-part-template';

	//###[ SCHEMA ]#########################################################################################################

	var SCHEMA = {
		type: 'object',
		properties: {
			'model': {
				type: 'object',
				properties: {
					// nothing yet
				}
			}
		}
	};

	//###[ STEPNAVIGATIONPART ]#############################################################################################

	/**
  * Manages the display and functionality for the step selection menu, based on the app's steps.
  **/

	var StepNavigationPart = exports.StepNavigationPart = function (_Part) {
		_inherits(StepNavigationPart, _Part);

		_createClass(StepNavigationPart, null, [{
			key: 'register',


			//###( STATIC )###

			value: function register() {
				_part.Part.register('StepNavigationPart', StepNavigationPart, TEMPLATE_SELECTOR);
			}

			//###( CLASS )###

		}]);

		function StepNavigationPart(params) {
			_classCallCheck(this, StepNavigationPart);

			// strip possible observables
			params = ko.viewmodel.toModel(params);
			var paramsInspection = _shim.SchemaInspector.validate(SCHEMA, params);
			$.assert(paramsInspection.valid, 'StepNavigationPart.constructor | params does not fit schema (' + paramsInspection.format() + ')');

			var _this = _possibleConstructorReturn(this, (StepNavigationPart.__proto__ || Object.getPrototypeOf(StepNavigationPart)).call(this, $.extend(true, {
				model: {
					// nothing yet
				}
			}, params)));

			_this.viewModel.stepInfo = ko.observableArray(_this.app.getStepInfo());

			_this._addDisposable(_this.app.viewModel.stepValuesChanged.subscribe(function (newValue) {
				_this.update();
			}));

			_this.update();
			return _this;
		}

		_createClass(StepNavigationPart, [{
			key: 'dispose',
			value: function dispose() {
				_get(StepNavigationPart.prototype.__proto__ || Object.getPrototypeOf(StepNavigationPart.prototype), 'dispose', this).call(this);
			}

			//###( FUNCTIONALITY )###

			/**
    * Updates step visibilities and the current one, based on info provided by the App.
    **/

		}, {
			key: 'update',
			value: function update() {
				var currentStepNumber = this.app.getCurrentStepNumber();

				_.each(this.viewModel.stepInfo(), function (stepInfoPart) {
					if (!$.isFunction(stepInfoPart.visible)) {
						stepInfoPart.visible = ko.observable(false);
					}

					if (!$.isFunction(stepInfoPart.selected)) {
						stepInfoPart.selected = ko.observable(false);
					}

					stepInfoPart.visible(stepInfoPart.number <= currentStepNumber);
					stepInfoPart.selected(stepInfoPart.number == currentStepNumber);
				});

				this.viewModel.stepInfo.notifySubscribers();
			}

			/**
    * Scrolls to the top of this step. If several scrolls are executed in a row (after rendering for example),
    * the .off() manages, that only the last animation runs through.
    **/

		}, {
			key: 'scrollToStep',
			value: function scrollToStep(stepInfo) {
				if (stepInfo.visible) {
					var innerHeight = window.innerHeight || $(window).height();
					$('#step-' + $.slugify(stepInfo.name)).first().scrollTo($.noop, 500, Math.round(innerHeight / 3), true);
				}
			}
		}]);

		return StepNavigationPart;
	}(_part.Part);
});
