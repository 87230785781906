define('bootstrap',['app/app'], function (_app) {
	'use strict';

	// ###[ KNOCKOUT ADDONS ]###############################################################################################

	/**
  * add a computed to knockout, that can manually trigger an value update with forceNotifySubscribers()
  **/
	ko.forcibleComputed = function (readFunc, context, options) {
		var trigger = ko.observable().extend({ notify: 'always' }),
		    target = ko.computed(function () {
			trigger();
			return readFunc.call(context);
		}, null, options);

		target.forceNotifySubscribers = function () {
			trigger.valueHasMutated();
		};

		return target;
	};

	/**
  * add a cloak binding to hide elements until the app is ready, uses css definition for .cloaked and data-bind="cloak: true"
  **/
	//###[ IMPORTS ]########################################################################################################

	ko.bindingHandlers.cloak = {
		init: function init(element, valueAccessor, allBindings, viewModel, bindingContext) {
			var fUncloak = function fUncloak($element) {
				$element.addClass('uncloaked');
			};

			if ($('#app').hasClass('app-ready')) {
				fUncloak($(element));
			} else {
				$('#app').one('ready.app', function () {
					fUncloak($(element));
				});
			}
		},
		update: function update(element, valueAccessor, allBindings, viewModel, bindingContext) {}
	};

	/**
  * add a binding to explicitly react to enter keypresses
  */
	ko.bindingHandlers.enter = {
		init: function init(element, valueAccessor, allBindings, viewModel, bindingContext) {
			var callback = valueAccessor();
			$.assert(_.isFunction(callback), 'ko.binding enter | value is no function');

			$(element).on('keydown.enterbinding', function (e) {
				if (e.keyCode === 13) {
					e.preventDefault();
					callback(e, element, viewModel);
					return false;
				}
			});
		},
		update: function update(element, valueAccessor, allBindings, viewModel, bindingContext) {}
	};

	// ###[ INIT ]##########################################################################################################

	/**
  * Wait for DOMReady and initialize the app with the config from the template
  **/
	$(function () {
		_app.App.registerComponents();
		var app = new _app.App();
		app.setConfig(window.DOLLE_CONFIG);
		app.applyToElement($('body'));

		if ($.contextIsTouchDevice()) {
			$('body').addClass('touch');
		}

		if (!app.getConfigItem('debug')) {
			$.log('__disable__');
		}
	});
});
