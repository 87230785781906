define('app/parts/cookielayer',['exports', 'lib/shim', 'app/abstract/part'], function (exports, _shim, _part) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.CookieLayerPart = undefined;

	function _classCallCheck(instance, Constructor) {
		if (!(instance instanceof Constructor)) {
			throw new TypeError("Cannot call a class as a function");
		}
	}

	function _possibleConstructorReturn(self, call) {
		if (!self) {
			throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
		}

		return call && (typeof call === "object" || typeof call === "function") ? call : self;
	}

	var _get = function get(object, property, receiver) {
		if (object === null) object = Function.prototype;
		var desc = Object.getOwnPropertyDescriptor(object, property);

		if (desc === undefined) {
			var parent = Object.getPrototypeOf(object);

			if (parent === null) {
				return undefined;
			} else {
				return get(parent, property, receiver);
			}
		} else if ("value" in desc) {
			return desc.value;
		} else {
			var getter = desc.get;

			if (getter === undefined) {
				return undefined;
			}

			return getter.call(receiver);
		}
	};

	var _createClass = function () {
		function defineProperties(target, props) {
			for (var i = 0; i < props.length; i++) {
				var descriptor = props[i];
				descriptor.enumerable = descriptor.enumerable || false;
				descriptor.configurable = true;
				if ("value" in descriptor) descriptor.writable = true;
				Object.defineProperty(target, descriptor.key, descriptor);
			}
		}

		return function (Constructor, protoProps, staticProps) {
			if (protoProps) defineProperties(Constructor.prototype, protoProps);
			if (staticProps) defineProperties(Constructor, staticProps);
			return Constructor;
		};
	}();

	function _inherits(subClass, superClass) {
		if (typeof superClass !== "function" && superClass !== null) {
			throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
		}

		subClass.prototype = Object.create(superClass && superClass.prototype, {
			constructor: {
				value: subClass,
				enumerable: false,
				writable: true,
				configurable: true
			}
		});
		if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
	}

	//###[ CONSTANTS ]######################################################################################################

	var TEMPLATE_SELECTOR = '#cookielayer-part-template';
	var COOKIE_DURATION = 14;
	var COOKIE_NAME = 'dolle_roofplanner_cookies_accepted';

	//###[ SCHEMA ]#########################################################################################################

	var SCHEMA = {
		type: 'object'
	};

	//###[ COOKIELAYER ]####################################################################################################

	/**
  * Manages the display and functionality for the cookie layer
  **/

	var CookieLayerPart = exports.CookieLayerPart = function (_Part) {
		_inherits(CookieLayerPart, _Part);

		_createClass(CookieLayerPart, null, [{
			key: 'register',


			//###( STATIC )###

			value: function register() {
				_part.Part.register('CookieLayerPart', CookieLayerPart, TEMPLATE_SELECTOR);
			}

			//###( CLASS )###

		}]);

		function CookieLayerPart(params) {
			_classCallCheck(this, CookieLayerPart);

			// strip possible observables
			params = ko.viewmodel.toModel(params);
			var paramsInspection = _shim.SchemaInspector.validate(SCHEMA, params);
			$.assert(paramsInspection.valid, 'CookieLayerPart.constructor | params does not fit schema (' + paramsInspection.format() + ')');

			return _possibleConstructorReturn(this, (CookieLayerPart.__proto__ || Object.getPrototypeOf(CookieLayerPart)).call(this, $.extend(true, {
				model: {
					isActive: false,
					page: null
				}
			}, params)));
		}

		_createClass(CookieLayerPart, [{
			key: 'dispose',
			value: function dispose() {
				_get(CookieLayerPart.prototype.__proto__ || Object.getPrototypeOf(CookieLayerPart.prototype), 'dispose', this).call(this);
			}

			//###( FUNCTIONALITY )###


		}, {
			key: 'manageVisibility',
			value: function manageVisibility() {
				if ($.cookie(COOKIE_NAME) !== 'true') {
					this.show();
				}
			}
		}, {
			key: 'show',
			value: function show() {
				var $footer = $('[data-id="' + this.viewModel.id() + '"]').closest('footer');

				this.viewModel.isActive(true);

				$footer.addClass('cookie-layer-active');
			}
		}, {
			key: 'hide',
			value: function hide() {
				var $footer = $('[data-id="' + this.viewModel.id() + '"]').closest('footer');

				this.viewModel.isActive(false);

				$footer.removeClass('cookie-layer-active');
			}
		}, {
			key: 'setCookie',
			value: function setCookie(hide) {
				hide = $.orDefault(hide, true, 'bool');

				$.cookie(COOKIE_NAME, 'true', { path: '/', expires: COOKIE_DURATION });

				if (hide) {
					this.hide();
				}
			}
		}, {
			key: 'showPage',
			value: function showPage(page) {
				var $message = $($('[data-id=' + this.viewModel.id() + ']').find('.cookie-layer-page .modal-content:first').html());

				this.app.showPage(page, $message);
			}
		}]);

		return CookieLayerPart;
	}(_part.Part);
});
