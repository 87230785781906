define('util/behaviour',['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.makeCollapsible = makeCollapsible;
	/**
  * Behaviours for standard elements not worthy of being a widget.
  **/

	/**
  * Make an element collapsible, opening and closing, letting the title stand, while animating the content body.
  * Must have "collapsible"-class, must have children .head and .body.
  **/
	function makeCollapsible($element) {
		$element.each(function () {
			$.assert($element.hasClass('collapsible'), 'makeCollapsible | $element lacks "collapsible"-class');
			$.assert($element.children('.head').length, 'makeCollapsible | $element lacks child "head"');
			$.assert($element.children('.body').length, 'makeCollapsible | $element lacks child "body"');

			var _this_ = this;

			$(this).children('.head').off('click.collapsible').on('click.collapsible', function () {
				var $body = $(_this_).children('.body'),
				    contentHeight = 0;

				if (!$(_this_).hasClass('open')) {
					$body.css('height', 'auto');
				}

				contentHeight = $body.height();

				if (!$(_this_).hasClass('open')) {
					$body.css('height', '0');
				} else {
					$body.css('height', contentHeight);
				}

				$(_this_).addClass('animated');

				$.schedule(10, function () {
					if (!$(_this_).hasClass('open')) {
						$body.css('height', contentHeight);
					} else {
						$body.css('height', '0');
					}

					$(_this_).toggleClass('open');

					// be careful with the ms, they're also in the sass
					$.schedule(250, function () {
						$(_this_).removeClass('animated');
						$body.css('height', '');
					});
				});
			});
		});
	}
});
