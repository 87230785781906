define('app/finalize/customerinquiry',['exports', 'util/object'], function (exports, _object) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.CustomerInquiry = undefined;

	function _classCallCheck(instance, Constructor) {
		if (!(instance instanceof Constructor)) {
			throw new TypeError("Cannot call a class as a function");
		}
	}

	var _createClass = function () {
		function defineProperties(target, props) {
			for (var i = 0; i < props.length; i++) {
				var descriptor = props[i];
				descriptor.enumerable = descriptor.enumerable || false;
				descriptor.configurable = true;
				if ("value" in descriptor) descriptor.writable = true;
				Object.defineProperty(target, descriptor.key, descriptor);
			}
		}

		return function (Constructor, protoProps, staticProps) {
			if (protoProps) defineProperties(Constructor.prototype, protoProps);
			if (staticProps) defineProperties(Constructor, staticProps);
			return Constructor;
		};
	}();

	var CustomerInquiry = exports.CustomerInquiry = function () {
		function CustomerInquiry(app, partlistUrlJson, customerInquiryTokenObj) {
			_classCallCheck(this, CustomerInquiry);

			var _this_ = this,
			    $finalize = $('.finalize').first(),
			    $customerInquiry = $finalize.find('.customerinquiry').first(),
			    customerInquiryUsage = (0, _object.deepSearchKeyValues)(app.getStepValues(), app.getConfigItem('customerInquiryUsageKey'));

			customerInquiryUsage = customerInquiryUsage.length > 0 ? _.first(customerInquiryUsage) : '';

			$customerInquiry.find('.basicdata').find(':input[name="partlisthash"]').val(partlistUrlJson.partlist.hash).end().find(':input[name="usage"]').val(customerInquiryUsage.description).end().find('.usage').text(customerInquiryUsage.description).end().end();

			if (!$customerInquiry.hasClass('js-enabled')) {
				$.setAdditionalValidationWidgetEvents(['keyup.validation']);

				$customerInquiry.addClass('js-enabled').find('select, :checkbox, :radio').each(function () {
					if (!$(this).hasClass('js-enabled')) {
						$(this).addClass('js-enabled');

						if ($(this).is(':checkbox')) {
							$(this).makeStylable('checkboxlabel');
						} else if ($(this).is(':radio')) {
							$(this).makeStylable('radiolabel');
						} else {
							$(this).makeStylable();
						}
					}
				}).end().find('.basicdata').find(':input[name="date"]').val(customerInquiryTokenObj.date).end().find('.date').text(customerInquiryTokenObj.date).end().find(':input[name="customerinquirytoken"]').val(customerInquiryTokenObj.token).end().find('.customerinquirytoken').text(customerInquiryTokenObj.token).end().end().find('.inputs').find('[name="firstname"]').setValidation([{ required: app.getText('validation.required') }], this.elementErrorDisplayCallback).end().find('[name="lastname"]').setValidation([{ required: app.getText('validation.required') }], this.elementErrorDisplayCallback).end().find('[name="street"]').setValidation([{ required: app.getText('validation.required') }], this.elementErrorDisplayCallback).end().find('[name="streetnumber"]').setValidation([{ required: app.getText('validation.required') }], this.elementErrorDisplayCallback).end().find('[name="postcode"]').setValidation([{ required: app.getText('validation.required') }, { digits: app.getText('validation.required') }, { minlength: $.strFormat(app.getText('validation.minlength'), { length: 5 }), args: 5 }, { maxlength: $.strFormat(app.getText('validation.maxlength'), { length: 5 }), args: 5 }], this.elementErrorDisplayCallback).end().find('[name="city"]').setValidation([{ required: app.getText('validation.required') }], this.elementErrorDisplayCallback).end().find('[name="telephone"]').setValidation([{ required: app.getText('validation.required') }, {
					customcase: app.getText('validation.phonenumber'), args: function args($target) {
						return (/^[0-9\ \-\+\/\\]+$/.test($target.val())
						);
					}
				}], this.elementErrorDisplayCallback).end().find('[name="email"]').setValidation([{ required: app.getText('validation.required') }, { email: app.getText('validation.email') }], this.elementErrorDisplayCallback).end().end().find('.optins').find('[name="optintermsandconditions"]').each(function () {
					$(this).setValidation([{ required: app.getText('validation.required') }], _this_.elementErrorDisplayCallback, $(this).closest('.checkboxlabel'));
				}).end().end().find('.submits').find('[name="submit"]').on('click', function () {
					$(this).closest('form').dataDuo('submittype', $(this).attr('value'));
				}).end().end().find('form').on('submit.validation', function (e, isValid) {
					e.preventDefault();

					if ($.isSet(isValid)) {
						var $retailers = $('.part-retailers').first();
						$retailers.find('.validationmessage').addClass('hidden');

						if (isValid) {
							var data = $(this).formDataToObject();

							if ($.isSet(data.retailer)) {
								var sendMail = $(this).dataDuo('submittype') === 'email';

								app.showLoadingOverlay(sendMail ? app.getText('loadingOverlay.mailSend') : app.getText('loadingOverlay.pdfGenerate'));

								var pdfWindow = null;
								if (!sendMail) {
									pdfWindow = $.openWindow('about:blank', { name: 'inquiry-pdf' });
								}

								$.ajax({
									url: sendMail ? '/inquiries/create-new/' : '/inquiries/create-new-for-pdf/',
									type: 'POST',
									contentType: 'application/json',
									dataType: 'json',
									data: JSON.stringify(data),
									headers: {
										'X-CSRFToken': $.cookie('csrftoken')
									}
								}).done(function (json) {
									if ($.isSet(json.success) && json.success) {
										if (sendMail) {
											app.showFinalMessage();
										} else if ($.isSet(pdfWindow)) {
											pdfWindow.location.href = '//' + $.strReplace('//', '/', $.strFormat('{host}{pathname}' + partlistUrlJson.partlist.url + '/inquiry-pdf/', window.location));
										}
									} else {
										$.warn($.strFormat('CustomerInquiry.constructor | submit answer contains error {error}', json));

										app.showError(app.getText('errors.defaultTitle'), $.elem('p').text(app.getText('errors.serverCommunicationErrorMessage')), true);
									}
								}).fail(function () {
									app.showError(app.getText('errors.defaultTitle'), $.elem('p').text(app.getText('errors.serverCommunicationErrorMessage')), true);
								}).always(function () {
									app.hideLoadingOverlay();
								});
							} else {
								$retailers.find('.validationmessage').removeClass('hidden').scrollTo($.noop, 500, 0, true);
							}
						} else {
							$(this).find('.validationerror').first().focus().scrollTo($.noop, 500, 0, true);
						}
					}
				}).end();
			}
		}

		_createClass(CustomerInquiry, [{
			key: 'elementErrorDisplayCallback',
			value: function elementErrorDisplayCallback(success, messages, $target) {
				var $label = $target.closest('label');

				$label.children('.validationmessage').remove();

				if (!success) {
					$label.append($.elem('div').addClass('validationmessage').text(_.first(messages)));
				}
			}
		}]);

		return CustomerInquiry;
	}();
});
