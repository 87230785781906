define('util/object',['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.deepSearchKeyValues = deepSearchKeyValues;

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	/**
  * Helpers for working with PlainObjects
  **/

	/**
  * recursively searches for all values of key in obj and returns result as array
  **/
	function deepSearchKeyValues(obj, key) {
		if (_.has(obj, key)) {
			return [obj[key]];
		}

		return _.flatten(_.map(obj, function (v) {
			return (typeof v === 'undefined' ? 'undefined' : _typeof(v)) == 'object' ? deepSearchKeyValues(v, key) : [];
		}), true);
	}
});
