define('app/abstract/part',['exports', 'lib/shim', 'app/abstract/viewmodel'], function (exports, _shim, _viewmodel) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.Part = undefined;

	function _classCallCheck(instance, Constructor) {
		if (!(instance instanceof Constructor)) {
			throw new TypeError("Cannot call a class as a function");
		}
	}

	function _possibleConstructorReturn(self, call) {
		if (!self) {
			throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
		}

		return call && (typeof call === "object" || typeof call === "function") ? call : self;
	}

	var _get = function get(object, property, receiver) {
		if (object === null) object = Function.prototype;
		var desc = Object.getOwnPropertyDescriptor(object, property);

		if (desc === undefined) {
			var parent = Object.getPrototypeOf(object);

			if (parent === null) {
				return undefined;
			} else {
				return get(parent, property, receiver);
			}
		} else if ("value" in desc) {
			return desc.value;
		} else {
			var getter = desc.get;

			if (getter === undefined) {
				return undefined;
			}

			return getter.call(receiver);
		}
	};

	var _createClass = function () {
		function defineProperties(target, props) {
			for (var i = 0; i < props.length; i++) {
				var descriptor = props[i];
				descriptor.enumerable = descriptor.enumerable || false;
				descriptor.configurable = true;
				if ("value" in descriptor) descriptor.writable = true;
				Object.defineProperty(target, descriptor.key, descriptor);
			}
		}

		return function (Constructor, protoProps, staticProps) {
			if (protoProps) defineProperties(Constructor.prototype, protoProps);
			if (staticProps) defineProperties(Constructor, staticProps);
			return Constructor;
		};
	}();

	function _inherits(subClass, superClass) {
		if (typeof superClass !== "function" && superClass !== null) {
			throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
		}

		subClass.prototype = Object.create(superClass && superClass.prototype, {
			constructor: {
				value: subClass,
				enumerable: false,
				writable: true,
				configurable: true
			}
		});
		if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
	}

	//###[ SCHEMA ]#########################################################################################################

	var SCHEMA = {
		type: 'object',
		properties: {
			'model': {
				type: 'object',
				properties: {
					'cssClasses': {
						optional: true,
						type: 'string'
					}
				}
			}
		}
	};

	//###[ PART ]###########################################################################################################

	/**
  * Abstract class to generically wrap autonomous parts of the user interface / app, to avoid blowing up the markup
  * and separate them logically.
  **/

	var Part = exports.Part = function (_ViewModel) {
		_inherits(Part, _ViewModel);

		_createClass(Part, null, [{
			key: 'register',


			//###( STATIC )###

			value: function register(type, PartClass, templateSelector) {
				ko.components.register('' + type, {
					viewModel: {
						createViewModel: function createViewModel(params) {
							return new PartClass(params);
						}
					},
					template: $('' + templateSelector).first().html()
				});
			}

			//###( CLASS )###

		}]);

		function Part(params) {
			_classCallCheck(this, Part);

			// strip possible observables
			params = ko.viewmodel.toModel(params);
			var paramsInspection = _shim.SchemaInspector.validate(SCHEMA, params);
			$.assert(paramsInspection.valid, 'Part.constructor | params does not fit schema (' + paramsInspection.format() + ')');

			var _this = _possibleConstructorReturn(this, (Part.__proto__ || Object.getPrototypeOf(Part)).call(this));

			_this.app = $.orDefault(params.app, null);
			$.assert($.exists('app.viewModel', _this), 'Part.constructor | app has not been set');

			_this.viewModel = ko.viewmodel.fromModel($.extend({
				//css classes to add to the part
				cssClasses: ''
			}, params.model));

			// id (for id attr), enables us to select the dom element dynamically
			_this.viewModel.id = ko.observable(_.uniqueId('part_'));
			return _this;
		}

		_createClass(Part, [{
			key: 'dispose',
			value: function dispose() {
				_get(Part.prototype.__proto__ || Object.getPrototypeOf(Part.prototype), 'dispose', this).call(this);
			}

			//###( CALLBACKS )###

			/**
    * called after render to apply addition classes from viewModel.cssClasses
    **/

		}, {
			key: 'addCssClasses',
			value: function addCssClasses(elements, $data) {
				$('[data-id="' + $data.viewModel.id() + '"]').addClass($data.viewModel.cssClasses());
			}
		}]);

		return Part;
	}(_viewmodel.ViewModel);
});
