define('app/step',['exports', 'lib/shim', 'app/abstract/viewmodel'], function (exports, _shim, _viewmodel) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.Step = exports.VALUES_SCHEMA = undefined;

	function _classCallCheck(instance, Constructor) {
		if (!(instance instanceof Constructor)) {
			throw new TypeError("Cannot call a class as a function");
		}
	}

	function _possibleConstructorReturn(self, call) {
		if (!self) {
			throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
		}

		return call && (typeof call === "object" || typeof call === "function") ? call : self;
	}

	var _get = function get(object, property, receiver) {
		if (object === null) object = Function.prototype;
		var desc = Object.getOwnPropertyDescriptor(object, property);

		if (desc === undefined) {
			var parent = Object.getPrototypeOf(object);

			if (parent === null) {
				return undefined;
			} else {
				return get(parent, property, receiver);
			}
		} else if ("value" in desc) {
			return desc.value;
		} else {
			var getter = desc.get;

			if (getter === undefined) {
				return undefined;
			}

			return getter.call(receiver);
		}
	};

	var _createClass = function () {
		function defineProperties(target, props) {
			for (var i = 0; i < props.length; i++) {
				var descriptor = props[i];
				descriptor.enumerable = descriptor.enumerable || false;
				descriptor.configurable = true;
				if ("value" in descriptor) descriptor.writable = true;
				Object.defineProperty(target, descriptor.key, descriptor);
			}
		}

		return function (Constructor, protoProps, staticProps) {
			if (protoProps) defineProperties(Constructor.prototype, protoProps);
			if (staticProps) defineProperties(Constructor, staticProps);
			return Constructor;
		};
	}();

	function _inherits(subClass, superClass) {
		if (typeof superClass !== "function" && superClass !== null) {
			throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
		}

		subClass.prototype = Object.create(superClass && superClass.prototype, {
			constructor: {
				value: subClass,
				enumerable: false,
				writable: true,
				configurable: true
			}
		});
		if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
	}

	//###[ CONSTANTS ]######################################################################################################

	var TEMPLATE_SELECTOR = '#step-template';

	//###[ SCHEMA ]#########################################################################################################

	var VALUE_SCHEMA = {
		type: 'object',
		properties: {
			value: { type: 'string' },
			description: { type: 'string' }
		}
	};

	// used to validate Step values structure via SchemaInspector externally, in the app for example
	var VALUES_SCHEMA = exports.VALUES_SCHEMA = {
		type: 'object',
		properties: {
			'*': VALUE_SCHEMA
		}
	};

	var SCHEMA = {
		type: 'object',
		properties: {
			'model': {
				type: 'object',
				properties: {
					'number': {
						optional: true,
						type: 'integer',
						gte: 1
					},

					'name': {
						type: 'string',
						pattern: 'alphaDash'
					},

					'headline': {
						optional: true,
						type: 'string'
					},

					'explanationText': {
						optional: true,
						type: 'string'
					},

					'neededTags': {
						optional: true,
						type: 'array',
						items: {
							type: 'string',
							pattern: 'alphaDash'
						}
					},

					'components': {
						// contents are further validated by each rendered component
						type: 'array',
						items: {
							type: 'object'
						}
					},

					'info': {
						// is validated by widget/info
						optional: true,
						type: 'object'
					}
				}
			}
		}
	};

	//###[ STEP ]###########################################################################################################

	/**
  * Semantic wrapper for one step of the config dialogue. Contains value bearing components, that register their values
  * with the step, which, in turn, registers its value dict with the app.
  **/

	var Step = exports.Step = function (_ViewModel) {
		_inherits(Step, _ViewModel);

		_createClass(Step, null, [{
			key: 'register',


			//###( STATIC )###

			value: function register() {
				ko.components.register('Step', {
					viewModel: {
						createViewModel: function createViewModel(params, componentInfo) {
							return new Step(params);
						}
					},
					template: $(TEMPLATE_SELECTOR).first().html()
				});
			}

			//###( CLASS )###

		}]);

		function Step(params) {
			_classCallCheck(this, Step);

			// strip possible observables
			params = ko.viewmodel.toModel(params);
			var paramsInspection = _shim.SchemaInspector.validate(SCHEMA, params);
			$.assert(paramsInspection.valid, 'Step.constructor | params does not fit schema (' + paramsInspection.format() + ')');

			// reference to the app this step is associated to
			var _this = _possibleConstructorReturn(this, (Step.__proto__ || Object.getPrototypeOf(Step)).call(this));

			_this.app = $.orDefault(params.app, null);
			$.assert($.exists('app.viewModel', _this), 'Step.constructor | app has not been set');

			_this.viewModel = ko.viewmodel.fromModel($.extend({
				// step count, 1-based, 0 is unset default
				number: 0,

				// internal identifier of the step, for example used for the app's stepValues dict
				name: '',

				// the headline preceding the step's contents
				headline: '',

				// the explanation text following the headline but preceding the step's contents
				explanationText: '',

				// the tags needed in the apps tag list to display this step
				neededTags: [],

				// components definition for rendering the step's components
				components: [],

				// additional information
				info: undefined
			}, params.model));

			// id (for id attr), enables us to select the dom element dynamically
			_this.viewModel.id = ko.observable(_.uniqueId('step_'));

			// dictionary of current component values in this step
			_this.viewModel.values = {};

			// initialize this step's values in the apps value dict
			_this.app.setStepValues(_this.viewModel.name(), _this.viewModel.values);

			// we need this to manually notify subscribers of a changed value
			_this.viewModel.valueChanged = ko.observable();

			// compile a string representation of the step's current state on a component's value change
			_this.viewModel.value = ko.computed(function () {
				_this.viewModel.valueChanged();

				var res = '';
				_.each(_this.values, function (value, key) {
					res += $.strFormat('{key}:{value}\n', { key: key, value: value.value });
				});

				return res;
			}).extend({ notify: 'always' });
			_this._addDisposable(_this.viewModel.value);

			// update the app's value dict on component's value change
			_this._addDisposable(_this.viewModel.value.subscribe(function (newValue) {
				_this.app.updateStepValues(_this.viewModel.name(), _this.viewModel.values);
			}));
			return _this;
		}

		_createClass(Step, [{
			key: 'dispose',
			value: function dispose() {
				// remove all traces of this step's values in the app's value dict
				this.app.removeStepValues(this.viewModel.name());

				_get(Step.prototype.__proto__ || Object.getPrototypeOf(Step.prototype), 'dispose', this).call(this);
			}

			//###( SETTER )###

		}, {
			key: 'setValue',
			value: function setValue(key, value) {
				var valueInspection = _shim.SchemaInspector.validate(VALUE_SCHEMA, value);
				$.assert(valueInspection.valid, 'Step.setValue | value do not fit schema (' + valueInspection.format() + ')');

				this.viewModel.values['' + key] = value;
				this.viewModel.valueChanged.notifySubscribers();
			}
		}, {
			key: 'updateValue',
			value: function updateValue(key, value) {
				$.assert($.isSet(this.viewModel.values['' + key]), 'Step.updateValue | key not defined, cannot update');
				var valueInspection = _shim.SchemaInspector.validate(VALUE_SCHEMA, value);
				$.assert(valueInspection.valid, 'Step.setValue | value do not fit schema (' + valueInspection.format() + ')');

				this.viewModel.values['' + key] = value;
				this.viewModel.valueChanged.notifySubscribers();
			}
		}, {
			key: 'removeValue',
			value: function removeValue(key) {
				if ($.isSet(this.viewModel.values['' + key])) {
					delete this.viewModel.values['' + key];
					this.viewModel.valueChanged.notifySubscribers();
				}
			}

			//###( FUNCTIONALITY )###

		}, {
			key: 'scrollTo',
			value: function scrollTo(elements, $data) {
				if ($data.viewModel.number() > 1 && $data.viewModel.number() == $data.app.getCurrentStepNumber()) {
					var innerHeight = window.innerHeight || $(window).height();

					$.schedule(100, function () {
						$('#step-' + $.slugify($data.viewModel.name())).first().scrollTo($.noop, 500, Math.round(innerHeight / 3), true);
					});
				}
			}
		}]);

		return Step;
	}(_viewmodel.ViewModel);
});
