define('util/value',["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isPositiveIntegerString = isPositiveIntegerString;
  /**
   * Helpers for value management and value information.
   **/

  /**
   * Check if a string contains the string representation of an integer and not
   * just something parsable.
   **/
  function isPositiveIntegerString(string) {
    return (/^(0|([1-9][0-9]*))$/.test(string)
    );
  }
});
