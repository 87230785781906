define('app/abstract/viewmodel',["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _classCallCheck(instance, Constructor) {
		if (!(instance instanceof Constructor)) {
			throw new TypeError("Cannot call a class as a function");
		}
	}

	var _createClass = function () {
		function defineProperties(target, props) {
			for (var i = 0; i < props.length; i++) {
				var descriptor = props[i];
				descriptor.enumerable = descriptor.enumerable || false;
				descriptor.configurable = true;
				if ("value" in descriptor) descriptor.writable = true;
				Object.defineProperty(target, descriptor.key, descriptor);
			}
		}

		return function (Constructor, protoProps, staticProps) {
			if (protoProps) defineProperties(Constructor.prototype, protoProps);
			if (staticProps) defineProperties(Constructor, staticProps);
			return Constructor;
		};
	}();

	var ViewModel = exports.ViewModel = function () {

		//###( CLASS )###

		function ViewModel() {
			_classCallCheck(this, ViewModel);

			// list of anything which should be manually disposed of, when object gets destroyed (subscriptions, computeds, ...)
			this._disposables = [];
		}

		_createClass(ViewModel, [{
			key: "dispose",
			value: function dispose() {
				if ($.isArray(this._disposables)) {
					_.each(this._disposables, function (disposable) {
						if ($.isFunction(disposable.dispose)) {
							disposable.dispose();
						}
					});
				}
			}

			//###( PROTECTED )###

		}, {
			key: "_addDisposable",
			value: function _addDisposable(disposable) {
				this._disposables.push(disposable);
			}
		}]);

		return ViewModel;
	}();
});
