define('app/widgets/image',['exports', 'lib/shim', 'app/abstract/widget'], function (exports, _shim, _widget) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.ImageWidget = undefined;

	function _classCallCheck(instance, Constructor) {
		if (!(instance instanceof Constructor)) {
			throw new TypeError("Cannot call a class as a function");
		}
	}

	function _possibleConstructorReturn(self, call) {
		if (!self) {
			throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
		}

		return call && (typeof call === "object" || typeof call === "function") ? call : self;
	}

	var _get = function get(object, property, receiver) {
		if (object === null) object = Function.prototype;
		var desc = Object.getOwnPropertyDescriptor(object, property);

		if (desc === undefined) {
			var parent = Object.getPrototypeOf(object);

			if (parent === null) {
				return undefined;
			} else {
				return get(parent, property, receiver);
			}
		} else if ("value" in desc) {
			return desc.value;
		} else {
			var getter = desc.get;

			if (getter === undefined) {
				return undefined;
			}

			return getter.call(receiver);
		}
	};

	var _createClass = function () {
		function defineProperties(target, props) {
			for (var i = 0; i < props.length; i++) {
				var descriptor = props[i];
				descriptor.enumerable = descriptor.enumerable || false;
				descriptor.configurable = true;
				if ("value" in descriptor) descriptor.writable = true;
				Object.defineProperty(target, descriptor.key, descriptor);
			}
		}

		return function (Constructor, protoProps, staticProps) {
			if (protoProps) defineProperties(Constructor.prototype, protoProps);
			if (staticProps) defineProperties(Constructor, staticProps);
			return Constructor;
		};
	}();

	function _inherits(subClass, superClass) {
		if (typeof superClass !== "function" && superClass !== null) {
			throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
		}

		subClass.prototype = Object.create(superClass && superClass.prototype, {
			constructor: {
				value: subClass,
				enumerable: false,
				writable: true,
				configurable: true
			}
		});
		if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
	}

	//###[ CONSTANTS ]######################################################################################################

	var TEMPLATE_SELECTOR = '#image-widget-template';

	//###[ SCHEMA ]#########################################################################################################

	var SCHEMA = {
		type: 'object',
		properties: {
			'model': {
				type: 'object',
				properties: {
					'url': {
						type: 'string',
						pattern: /^\/?.+\/.+\.(gif|jpg|jpeg|png)$/
					},

					'urlHighDpi': {
						optional: true,
						type: 'string',
						pattern: /^\/?.+\/.+\.(gif|jpg|jpeg|png)$/
					},

					'altText': {
						optional: true,
						type: 'string'
					},

					'description': {
						optional: true,
						type: 'string'
					}
				}
			}
		}
	};

	//###[ IMAGEWIDGET ]####################################################################################################

	/**
  * Default widget for images. Incorporates default high DPI behaviour and aspect ratio behaviour.
  **/

	var ImageWidget = exports.ImageWidget = function (_Widget) {
		_inherits(ImageWidget, _Widget);

		_createClass(ImageWidget, null, [{
			key: 'register',


			//###( STATIC )###

			value: function register() {
				_widget.Widget.register('ImageWidget', ImageWidget, TEMPLATE_SELECTOR);
			}

			//###( CLASS )###

		}]);

		function ImageWidget(params) {
			_classCallCheck(this, ImageWidget);

			// strip possible observables
			params = ko.viewmodel.toModel(params);
			var paramsInspection = _shim.SchemaInspector.validate(SCHEMA, params);
			$.assert(paramsInspection.valid, 'ImageWidget.constructor | params does not fit schema (' + paramsInspection.format() + ')');

			var _this = _possibleConstructorReturn(this, (ImageWidget.__proto__ || Object.getPrototypeOf(ImageWidget)).call(this, $.extend(true, {
				model: {
					// URL of regular image version
					url: '',

					// URL of high DPI image version
					urlHighDpi: '',

					// img alt text
					altText: '',

					// image description, will be used as the img's title
					description: ''
				}
			}, params)));

			if (_.trim(_this.viewModel.urlHighDpi()) === '') {
				_this.viewModel.urlHighDpi(_this.viewModel.url());
			}

			_this.viewModel.srcSet = ko.computed(function () {
				_this.viewModel.url();
				_this.viewModel.urlHighDpi();

				return $.strFormat('{url} 1x, {urlHighDpi} 2x', { url: _this.viewModel.url(), urlHighDpi: _this.viewModel.urlHighDpi() });
			});
			_this._addDisposable(_this.viewModel.srcSet);
			return _this;
		}

		_createClass(ImageWidget, [{
			key: 'dispose',
			value: function dispose() {
				_get(ImageWidget.prototype.__proto__ || Object.getPrototypeOf(ImageWidget.prototype), 'dispose', this).call(this);
			}

			//###( CALLBACKS )###

			/**
    * called by template through afterRender, dynamically prepares the image with picturefill
    **/

		}, {
			key: 'prepareImage',
			value: function prepareImage(elements, $data) {
				(0, _shim.picturefill)({
					elements: [$('[data-id="' + $data.viewModel.id() + '"]').oo()]
				});
			}
		}]);

		return ImageWidget;
	}(_widget.Widget);
});
