define('app/subcomponents/optionasymmetricselectelement',['exports', 'lib/shim', 'app/abstract/subcomponent', 'app/subcomponents/selectelement'], function (exports, _shim, _subcomponent, _selectelement) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.OptionAsymmetricSelectElementSubComponent = undefined;

	function _classCallCheck(instance, Constructor) {
		if (!(instance instanceof Constructor)) {
			throw new TypeError("Cannot call a class as a function");
		}
	}

	function _possibleConstructorReturn(self, call) {
		if (!self) {
			throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
		}

		return call && (typeof call === "object" || typeof call === "function") ? call : self;
	}

	var _get = function get(object, property, receiver) {
		if (object === null) object = Function.prototype;
		var desc = Object.getOwnPropertyDescriptor(object, property);

		if (desc === undefined) {
			var parent = Object.getPrototypeOf(object);

			if (parent === null) {
				return undefined;
			} else {
				return get(parent, property, receiver);
			}
		} else if ("value" in desc) {
			return desc.value;
		} else {
			var getter = desc.get;

			if (getter === undefined) {
				return undefined;
			}

			return getter.call(receiver);
		}
	};

	var _createClass = function () {
		function defineProperties(target, props) {
			for (var i = 0; i < props.length; i++) {
				var descriptor = props[i];
				descriptor.enumerable = descriptor.enumerable || false;
				descriptor.configurable = true;
				if ("value" in descriptor) descriptor.writable = true;
				Object.defineProperty(target, descriptor.key, descriptor);
			}
		}

		return function (Constructor, protoProps, staticProps) {
			if (protoProps) defineProperties(Constructor.prototype, protoProps);
			if (staticProps) defineProperties(Constructor, staticProps);
			return Constructor;
		};
	}();

	function _inherits(subClass, superClass) {
		if (typeof superClass !== "function" && superClass !== null) {
			throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
		}

		subClass.prototype = Object.create(superClass && superClass.prototype, {
			constructor: {
				value: subClass,
				enumerable: false,
				writable: true,
				configurable: true
			}
		});
		if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
	}

	//###[ CONSTANTS ]######################################################################################################

	var TEMPLATE_SELECTOR = '#selectelement-subcomponent-template';

	//###[ OPTIONASYMMETRICSELECTELEMENTSUBCOMPONENT ]######################################################################################

	/**
  * A subcomponent of DimensionsComponent
  **/

	var OptionAsymmetricSelectElementSubComponent = exports.OptionAsymmetricSelectElementSubComponent = function (_SelectElementSubComp) {
		_inherits(OptionAsymmetricSelectElementSubComponent, _SelectElementSubComp);

		_createClass(OptionAsymmetricSelectElementSubComponent, null, [{
			key: 'register',


			//###( STATIC )###

			value: function register() {
				_subcomponent.SubComponent.register('OptionAsymmetricSelectElementSubComponent', OptionAsymmetricSelectElementSubComponent, TEMPLATE_SELECTOR);
			}

			//###( CLASS )###

		}]);

		function OptionAsymmetricSelectElementSubComponent(params) {
			_classCallCheck(this, OptionAsymmetricSelectElementSubComponent);

			// strip possible observables
			params = _shim.knockout.viewmodel.toModel(params);

			var _this = _possibleConstructorReturn(this, (OptionAsymmetricSelectElementSubComponent.__proto__ || Object.getPrototypeOf(OptionAsymmetricSelectElementSubComponent)).call(this, _shim.jQuery.extend(true, {
				model: {
					// nothing yet
				}
			}, params)));

			_this.viewModel.text(_this.app.getText('dimensions.resultList') + ':');

			_this.viewModel.list = _shim.knockout.viewmodel.fromModel({
				type: 'result',
				items: [],
				rawItems: []
			});

			_this.viewModel.hasText = _shim.knockout.computed(function () {
				return _this.component.viewModel.approvedWidthXlength() !== '';
			});
			_this._addDisposable(_this.viewModel.hasText);

			//^^^
			_this.viewModel.description = _shim.knockout.observable(_this.viewModel.title());

			_this._addDisposable(_this.component.viewModel.approvedWidthXlength.subscribe(function (newValue) {
				_this.viewModel.list.rawItems.removeAll();
				_this.viewModel.list.items.removeAll();

				var widthXlength = newValue.split('x'),
				    width = parseInt(widthXlength[0], 10),
				    minWidth = _this.component.viewModel.plateWidths.meta.minWidth(),
				    length = parseInt(widthXlength[1], 10),
				    effectivePlateWidths = _shim.lodash.reverse(_shim.lodash.sortBy(_this.component.viewModel.plateWidths.effective())),
				    plateGap = _this.component.viewModel.plateWidths.meta.spaceBetween(),
				    restWidth = width - _this.component.viewModel.plateWidths.meta.spaceLeftRight() * 2,
				    res = {};

				// continue adding plates until width is filled

				var _loop = function _loop() {
					var found = false,

					// think of the gap being on the left, the first does not get one
					currentPlateGap = _shim.jQuery.isEmptyObject(res) ? 0 : plateGap;

					// try to fit a regular plate in the remaining space with enough space to add another minimal plate
					_shim.lodash.each(effectivePlateWidths, function (plateWidth) {
						if (
						// just take plate if its the only one and fits exactly
						_shim.jQuery.isEmptyObject(res) && restWidth == plateWidth ||
						// or if remaining space can be filled exactly by a plate
						plateWidth + currentPlateGap == restWidth
						// else make sure we can actually still add a second one when taking a full plate
						|| restWidth >= plateWidth + (currentPlateGap + plateGap) + minWidth) {
							if (!_shim.jQuery.isSet(res['' + plateWidth])) {
								res['' + plateWidth] = {
									count: 0,
									width: _this.component.viewModel.plateWidths.structured['' + plateWidth],
									length: length,
									id: _this.component.viewModel.plateWidths.ids['' + plateWidth]
								};
							}

							res['' + plateWidth].count++;
							restWidth -= plateWidth + currentPlateGap;

							found = true;
							return false;
						}
					});

					// if we could not add a full plate, but the remaining space is still bigger than the plates,
					// we fit two equally cut into it
					if (!found) {
						_shim.lodash.each(effectivePlateWidths, function (plateWidth) {
							if (restWidth >= plateWidth + currentPlateGap) {
								var fallbackWidth = Math.ceil((restWidth - (currentPlateGap + plateGap)) / 2);

								res['' + fallbackWidth] = {
									count: 2,
									width: fallbackWidth,
									length: length,
									id: _this.component.viewModel.plateWidths.ids['' + plateWidth]
								};

								restWidth = 0;

								found = true;
								return false;
							}
						});
					}

					// if the remaining space is smaller than all plates, cut the smallest and insert it
					if (!found) {
						res['' + (restWidth - currentPlateGap)] = {
							count: 1,
							width: restWidth - currentPlateGap,
							length: length,
							id: _this.component.viewModel.plateWidths.ids['' + _shim.lodash.last(effectivePlateWidths)]
						};

						restWidth = 0;
					}
				};

				while (restWidth > 0) {
					_loop();
				}

				_shim.lodash.each(effectivePlateWidths, function (plateWidth) {
					if (_shim.jQuery.isSet(res['' + plateWidth])) {
						var item = {
							count: res['' + plateWidth].count,
							width: res['' + plateWidth].width,
							length: res['' + plateWidth].length,
							id: res['' + plateWidth].id
						};

						_this.viewModel.list.rawItems.push(_shim.jQuery.extend({}, item));
						_this.viewModel.list.items.push(_shim.jQuery.strFormat(_this.app.getText('dimensions.resultListItem'), item));

						delete res['' + plateWidth];
					}
				});

				_shim.lodash.each(res, function (restItem) {
					var item = {
						count: restItem.count,
						width: restItem.width,
						length: restItem.length,
						id: restItem.id
					};

					_this.viewModel.list.rawItems.push(_shim.jQuery.extend({}, item));
					_this.viewModel.list.items.push(_shim.jQuery.strFormat(_this.app.getText('dimensions.resultListItem'), item));
				});

				_this.viewModel.description(_this.viewModel.title() + ';' + _this.viewModel.list.items().join(';'));
			}));
			return _this;
		}

		_createClass(OptionAsymmetricSelectElementSubComponent, [{
			key: 'dispose',
			value: function dispose() {
				_get(OptionAsymmetricSelectElementSubComponent.prototype.__proto__ || Object.getPrototypeOf(OptionAsymmetricSelectElementSubComponent.prototype), 'dispose', this).call(this);
			}

			//###( FUNCTIONALITY )###

		}, {
			key: 'select',
			value: function select() {
				this.trackSelection();

				var compiledValue = this.viewModel.value() + '|' + this.component.viewModel.approvedWidthXlength() + '|';
				_shim.lodash.each(this.viewModel.list.rawItems(), function (item, index) {
					if (index > 0) {
						compiledValue += ';';
					}
					compiledValue += _shim.jQuery.strFormat('{count}*{width}x{length}({id})', item);
				});

				this.component.selectOption(compiledValue, this.viewModel.description());
			}
		}]);

		return OptionAsymmetricSelectElementSubComponent;
	}(_selectelement.SelectElementSubComponent);
});
